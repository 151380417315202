import { matchPath, useNavigate } from "react-router-dom";
import { RouteName } from "../Routes";

export const useLinkTo = () => {
  const navigate = useNavigate();

  const linkTo = (
    path: RouteName,
    params?: {
      postId?: string;
      tag?: string;
      hangName?: string;
      replyToId?: string;
      pagination?: number;
    }
  ) => {
    if (matchPath(RouteName.Explore, path)) {
      navigate(RouteName.Explore);
      return;
    }

    if (matchPath(RouteName.Home, path)) {
      navigate(RouteName.Home);
      return;
    }

    // if (matchPath(RouteName.LogIn, path)) {
    //   navigate(RouteName.LogIn);
    //   return;
    // }

    if (matchPath(RouteName.Notifications, path)) {
      navigate(RouteName.Notifications);
      return;
    }

    if (matchPath(RouteName.Post, path)) {
      if (!params?.postId) {
        throw new Error("postId is required");
      }
      navigate(
        `/posts/${params.postId}${
          params.pagination ? `/${params.pagination}` : ""
        }`
      );
      return;
    }

    if (matchPath(RouteName.Profile, path)) {
      if (!params?.hangName) {
        throw new Error("hangName is required");
      }
      navigate(`/users/${params.hangName}`);
      return;
    }

    if (matchPath(RouteName.ProfileEdit, path)) {
      navigate(RouteName.ProfileEdit);
      return;
    }

    if (matchPath(RouteName.Tag, path)) {
      if (!params?.tag) {
        throw new Error("tag is required");
      }
      navigate(`/tag/${params.tag}`);
      return;
    }

    if (matchPath(RouteName.SavedPosts, path)) {
      navigate(RouteName.SavedPosts);
      return;
    }

    if (matchPath(RouteName.AccountSettings, path)) {
      navigate(RouteName.AccountSettings);
      return;
    }

    if (matchPath(RouteName.EditHangName, path)) {
      navigate(RouteName.EditHangName);
      return;
    }

    if (matchPath(RouteName.BlockedUsers, path)) {
      navigate(RouteName.BlockedUsers);
      return;
    }

    if (matchPath(RouteName.DeleteUser, path)) {
      navigate(RouteName.DeleteUser);
      return;
    }

    if (matchPath(RouteName.Terms, path)) {
      navigate(RouteName.Terms);
      return;
    }

    if (matchPath(RouteName.Search, path)) {
      navigate(RouteName.Search);
      return;
    }

    if (RouteName.CreatePost === path) {
      navigate(
        `/create-post${params?.replyToId ? `/${params?.replyToId}` : ""}`
      );
      return;
    }
  };

  return linkTo;
};
