import React from "react";

export const YgraLogo = ({ style }: { style: React.CSSProperties }) => (
  <svg viewBox="0 0 206 191" style={style} fill="none">
    <path
      d="M175.572 1.69461L168.333 37.4091L205.692 36.9921C203.91 51.6901 199.778 67.6946 190.494 75.2176C172.905 89.4714 127.955 80.4625 119.366 78.5723C118.507 78.3832 117.901 77.6638 117.867 76.7849C117.523 68.1316 116.644 23.525 134.211 9.28921C143.498 1.76397 160.41 0.722669 175.572 1.69461Z"
      fill="white"
    />
    <path
      d="M0 32.453L37.1702 36.2031L33.3215 0C48.5089 0.382258 65.2471 2.92467 73.7875 11.2413C89.9529 26.9832 84.8882 71.3398 83.7362 79.9088C83.6194 80.7773 82.953 81.436 82.0839 81.5474C73.3707 82.6635 27.7383 87.6422 11.5522 71.8801C3.00919 63.5609 0.395452 47.2501 0 32.453Z"
      fill="white"
    />
    <path
      d="M33.3207 191L37.1702 154.79L0.00744008 158.539C0.399832 143.744 3.00964 127.438 11.5468 119.118C27.7267 103.35 73.3554 108.317 82.0682 109.43C82.9374 109.542 83.6039 110.2 83.7209 111.069C84.8752 119.638 89.9522 163.998 73.7929 179.746C65.2532 188.069 48.51 190.615 33.3207 191Z"
      fill="white"
    />
  </svg>
);
