import React from "react";

const RePostIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.37927 1.95239C3.10313 1.95239 2.87927 2.17625 2.87927 2.45239C2.87927 2.72853 3.10313 2.95239 3.37927 2.95239H9.86266L9.6134 6.19121L9.31691 5.76804C9.15846 5.54188 8.84668 5.48699 8.62052 5.64544C8.39436 5.80389 8.33947 6.11568 8.49792 6.34184L9.59532 7.90816C9.6806 8.02988 9.8156 8.10735 9.96371 8.11957C10.1118 8.13179 10.2577 8.07748 10.3618 7.97138L11.8981 6.40506C12.0915 6.20792 12.0884 5.89135 11.8913 5.69799C11.6942 5.50462 11.3776 5.50768 11.1842 5.70482L10.6086 6.29164L10.9011 2.49076C10.9118 2.3519 10.8641 2.21487 10.7695 2.11267C10.6749 2.01048 10.5419 1.95239 10.4026 1.95239H3.37927ZM9.14062 11.5672C9.41677 11.5672 9.64062 11.3434 9.64062 11.0672C9.64062 10.7911 9.41677 10.5672 9.14062 10.5672L3.0905 10.5672L3.29941 7.41847L3.51007 7.73918C3.66167 7.96999 3.97167 8.03419 4.20248 7.88259C4.43329 7.73099 4.49749 7.42099 4.34589 7.19018L3.31708 5.62386C3.2325 5.49509 3.09327 5.4125 2.93971 5.4C2.78615 5.38751 2.63541 5.44651 2.53112 5.55992L1.09079 7.12624C0.903873 7.32951 0.917127 7.64581 1.12039 7.83273C1.32366 8.01964 1.63996 8.00639 1.82688 7.80312L2.30628 7.28178L2.05733 11.0341C2.04817 11.1722 2.09662 11.3079 2.19116 11.4089C2.2857 11.5099 2.41788 11.5672 2.55623 11.5672L9.14062 11.5672Z"
      fill="white"
    />
  </svg>
);

export default RePostIcon;
