import {serverURL} from '../consts/serverURL';
import {System} from '../types/System';
import {Notification} from '../types/Notification';

export const apiGetNotifications = (token: System['token']) => async () => {
  const data = await fetch(`${serverURL}/notifications`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: String(token),
    },
  }).then(res => res.json());

  return data as Notification[];
};

export const apiGetNotificationCount = (token: System['token']) => async () => {
  const data = await fetch(`${serverURL}/notifications/count`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: String(token),
    },
  }).then(res => res.json());

  return data as number;
};
