import {serverURL} from '../consts/serverURL';
import {System} from '../types/System';
import {User} from '../types/User';

export const apiCreateBlock =
  (token: System['token']) => async (id: User['id']) =>
    await fetch(`${serverURL}/blocks`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
      body: JSON.stringify({id}),
    });

export const apiDeleteBlock =
  (token: System['token']) => async (id: User['id']) =>
    await fetch(`${serverURL}/blocks`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
      body: JSON.stringify({id}),
    });

export const apiGetBlocks = (token: System['token']) => async () => {
  const data = await fetch(`${serverURL}/blocks`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: String(token),
    },
  }).then(res => res.json());

  return data as User[];
};

export const apiGetUserIsBlocked =
  (token: System['token']) => async (id: User['id']) => {
    const data = await fetch(`${serverURL}/blocks/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
    }).then(res => res.json());

    return data as boolean;
  };
