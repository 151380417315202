import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import React from "react";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { YgraLogo } from "../../assets/YgraLogo";
import { Colors } from "../../../Mono/consts/Colors";
import {
  Box,
  Button,
  ButtonSizes,
  HStack,
  Spacer,
  Text,
  Touchable,
  VStack,
} from "../../Components/UI";
import { TextInput } from "../../Components/UX";
import { termsUri } from "../../../Mono/consts/termsUri";
import { LoadingOverlay } from "../../Components/UX/LoadingOverlay";

export const LoginPage = () => {
  const mode = useGetWindowMode();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [status, setStatus] = React.useState<"login" | "signup">("login");
  const [loading, setLoading] = React.useState(false);

  const onLogIn = () => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        console.log(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onSignUp = () => {
    setLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        console.log(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onSubmit = () => (status === "login" ? onLogIn() : onSignUp());

  return (
    <Box center height="80vh">
      <Box
        width="100%"
        style={{
          maxWidth: 350,
        }}
      >
        <Spacer y={mode === "web" ? 150 : 70} />

        <VStack center>
          <YgraLogo style={{ width: 90 }} />
        </VStack>

        <Spacer y={60} />
        <HStack spaceBetween>
          <Touchable onPress={() => setStatus("login")}>
            <Box spacing={20}>
              <Text color={status === "login" ? Colors.White : Colors.Grey}>
                ログイン
              </Text>
            </Box>
          </Touchable>

          <Touchable onPress={() => setStatus("signup")}>
            <Box spacing={20}>
              <Text color={status === "signup" ? Colors.White : Colors.Grey}>
                アカウント制作
              </Text>
            </Box>
          </Touchable>
        </HStack>

        <Box flex color={Colors.Grey} height={1} />

        <Spacer y={30} />

        <VStack>
          <TextInput
            value={email}
            placeholder={"メールアドレス"}
            onChange={setEmail}
          />

          <Spacer y={20} />

          <TextInput
            value={password}
            placeholder={"パスワード"}
            type="password"
            onChange={setPassword}
          />
        </VStack>

        <Spacer y={10} />

        {status === "signup" ? (
          <Box>
            <p style={{ color: Colors.Grey }}>
              新規登録することにより
              <a href={termsUri} style={{ color: Colors.White }}>
                ゆらぐら利用規約
              </a>
              へ同意することを示すものとします。
            </p>
          </Box>
        ) : (
          <Spacer y={10} />
        )}

        <Spacer y={10} />

        <Button
          text={status === "login" ? "ログイン" : "アカウント制作"}
          onPress={onSubmit}
          center
          bold
          size={ButtonSizes.Large}
        />

        <Spacer y={100} />
      </Box>
      <LoadingOverlay display={loading} />
    </Box>
  );
};
