import React from 'react';
import {Post} from '../../types/Post';

export function useCreatePostsQueryHook({
  query,
  params,
  initialOffset,
  cachePosts,
}: {
  query: (params: any, offset?: number) => Promise<Post[]>;
  params?: any;
  initialOffset?: number;
  cachePosts: (psots: Post[]) => void;
}): {
  posts: Post[] | null;
  endReached: boolean;
  pagination: number;
  reload: () => Promise<void>;
  getMore: () => void;
  setPosts: (t: Post[]) => void;
  clear: () => void;
  loading: boolean;
} {
  const [posts, setPostsState] = React.useState<Post[] | null>(null);
  const [pagination, setPagination] = React.useState(initialOffset || 0);
  const [endReached, setEndReached] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);

  const setPosts = (posts: Post[] | null) => {
    if (!posts) {
      setPostsState(null);
      return;
    }

    setPostsState(posts);
    cachePosts(posts);
  };

  const addPosts = (newPosts: Post[]) => {
    cachePosts(newPosts);
    setPosts([...(posts || []), ...newPosts]);
  };

  const checkEndReached = (newPosts: Post[]) => {
    if (newPosts?.length < 10) {
      setEndReached(true);
    }
  };

  React.useEffect(() => {
    setPagination(0);
    setPosts(null);
    if (params) {
      query(params).then(r => {
        addPosts(r);
        setPagination(val => val + 10);
        setLoading(false);
        checkEndReached(r);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const reload = async () => {
    if (!params) return;
    setLoading(true);
    setPosts(null);

    return await query(params).then(r => {
      setPagination(10);
      cachePosts(r);
      setPosts(r);

      setLoading(false);
      checkEndReached(r);
      return;
    });
  };

  const getMore = () => {
    if (params && pagination && posts?.length) {
      setLoading(true);
      query(params, pagination).then(r => {
        if (r?.length === 0) {
          setEndReached(true);
          setLoading(false);
          return;
        }
        setPagination(val => val + 10);
        addPosts(r);
        setLoading(false);
        checkEndReached(r);
      });
    }
  };

  const clear = () => {
    setPosts(null);
    setPagination(0);
    setEndReached(false);
  };

  return {
    posts,
    endReached,
    pagination,
    reload,
    getMore,
    setPosts,
    clear,
    loading,
  };
}
