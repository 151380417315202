import React from "react";
import { useScrollEnd } from "../../../../hooks/useScrollEnd";

type Props = {
  children: React.ReactNode;
  flowInOnRender?: boolean;
  onEndReached: () => void;
};

export const PageContainer = ({
  children,
  flowInOnRender,
  onEndReached,
}: Props) => {
  const { isAtEnd, ref } = useScrollEnd(2);

  React.useEffect(() => {
    if (isAtEnd) onEndReached();
  }, [isAtEnd]);

  const [flowIn, setFlowIn] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setFlowIn(true);
    }, 60);
  }, []);

  return (
    <div
      ref={ref}
      style={{
        overflowY: "auto",
        height: "100vh",

        ...(flowInOnRender && {
          opacity: flowIn ? 1 : 0,
          transition: "all 0.2s ease-in-out",
          transform: flowIn ? "translateY(0)" : "translateY(20px)",
        }),
      }}
    >
      {children}
    </div>
  );
};
