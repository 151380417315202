import React from "react";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { Icon, IconType } from "../../assets/icons";
import { Box, HStack, Spacer, Text, Touchable } from "../UI";
import { RouteName } from "../../../Routes";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { useGetPost, useGetUserByHangName } from "../../../Mono/queryHooks";
import service from "../../../Mono/service";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Colors } from "../../../Mono/consts/Colors";
import { useBottomSheet } from "../../BottomSheet/BottomSheetContext";
import { CircleButton } from ".";
import { YgraPurpleGlowLogo } from "../../assets/YgraPurpleGlowLogo";

const useGetNotificationCountByInterval = () => {
  const [notificationCount, setNotificationCount] = React.useState<number>(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      service
        .api()
        .notifications.getNotificationCount()
        .then((res) => {
          setNotificationCount(res);
        });
    }, 40000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return notificationCount;
};

const useGetHeaderParamsFromRoute = (
  route: string
): {
  leftButton?: {
    icon: IconType;
    onClick: () => void;
  };
  rightButton?: {
    icon: IconType;
    onClick: () => void;
  };
  title?: string;
} | null => {
  const navigate = useNavigate();
  const linkTo = useLinkTo();

  const postId = matchPath(RouteName.Post, route) ? route.split("/")[2] : "";
  const post = useGetPost(postId);
  const user = useGetUserByHangName(
    matchPath(RouteName.Profile, route) ? route.split("/")[2] : ""
  );
  const bottomSheet = useBottomSheet();

  if (matchPath(RouteName.Post, route)) {
    const postId = route.split("/")[2];

    return {
      leftButton: {
        icon: IconType.Left,
        onClick: () => navigate(-1),
      },
      rightButton: {
        icon: IconType.TripleDot,
        onClick: () =>
          postId
            ? bottomSheet.openPostModal({
                postId,
                userId: post?.val?.user?.id || "",
                onDeletePost: () => navigate(-1),
              })
            : null,
      },
      title: "ポスト",
    };
  }

  if (
    matchPath(RouteName.Profile, route) &&
    service.state.user?.hangName !== user.val?.hangName
  ) {
    return {
      leftButton: {
        icon: IconType.Left,
        onClick: () => navigate(-1),
      },
      title: user.val?.name || "",
    };
  }

  if (matchPath(RouteName.AccountSettings, route)) {
    return {
      leftButton: {
        icon: IconType.Left,
        onClick: () =>
          linkTo(RouteName.Profile, { hangName: service.state.user?.hangName }),
      },
      title: "アカウント設定",
    };
  }

  if (matchPath(RouteName.EditHangName, route)) {
    return {
      leftButton: {
        icon: IconType.Left,
        onClick: () => linkTo(RouteName.AccountSettings),
      },

      title: "ハングネーム変更",
    };
  }

  if (
    matchPath(RouteName.CreatePost, route) ||
    matchPath("/create-post", route)
  ) {
    return {
      leftButton: {
        icon: IconType.Left,
        onClick: () => navigate(-1),
      },
      title: "投稿",
    };
  }

  if (matchPath(RouteName.BlockedUsers, route)) {
    return {
      leftButton: {
        icon: IconType.Left,
        onClick: () =>
          linkTo(RouteName.Profile, { hangName: service.state.user?.hangName }),
      },

      title: "ブロックユーザー",
    };
  }

  if (matchPath(RouteName.SavedPosts, route)) {
    return {
      leftButton: {
        icon: IconType.Left,
        onClick: () =>
          linkTo(RouteName.Profile, { hangName: service.state.user?.hangName }),
      },

      title: "保存",
    };
  }

  if (matchPath(RouteName.Notifications, route) && service.state.user) {
    return {
      title: "通知",
    };
  }

  if (matchPath(RouteName.ProfileEdit, route)) {
    return {
      title: "プロフィール編集",
      leftButton: {
        icon: IconType.Left,
        onClick: () =>
          linkTo(RouteName.Profile, { hangName: service.state.user?.hangName }),
      },
    };
  }

  if (matchPath(RouteName.Tag, route)) {
    return {
      title: "タグ",
      leftButton: {
        icon: IconType.Left,
        onClick: () => navigate(-1),
      },
    };
  }

  return null;
};

export const WebNavComponent = () => {
  const mode = useGetWindowMode();

  const profileLink = service.state?.user?.hangName
    ? `/users/${service.state?.user?.hangName}`
    : null;

  const location = useLocation();
  const headerParams = useGetHeaderParamsFromRoute(location.pathname);
  const notificationCount = useGetNotificationCountByInterval();

  const displayPlusButton =
    matchPath(RouteName.Home, location.pathname) ||
    matchPath(RouteName.Profile, location.pathname)?.params.hangName ===
      service.state.user?.hangName;

  if (mode === "mobile") {
    return (
      <>
        {headerParams ? (
          <>
            <HeaderComponent {...headerParams} />
            <Box height={65} />
          </>
        ) : null}
        {displayPlusButton && service.state.user && (
          <Box
            style={{
              position: "absolute",
              right: 20,
              zIndex: 100,
              bottom: 80,
            }}
          >
            <Link to={"/create-post"}>
              <CircleButton />
            </Link>
          </Box>
        )}
        <MobileBottomMenu
          profileLink={profileLink}
          isLoggedIn={Boolean(service.state.user)}
          notificationCount={notificationCount}
        />
      </>
    );
  }

  return (
    <>
      <Box center>
        <Box
          style={{
            maxWidth: 1100,
            width: "100%",

            position: "fixed",
            top: 0,
            zIndex: 1000,
          }}
        >
          <DesktopNavComponent
            profileLink={profileLink}
            isLoggedIn={Boolean(service.state.user)}
            notificationCount={notificationCount}
            headerParams={headerParams}
          />
        </Box>
      </Box>
    </>
  );
};

const MobileBottomMenu = ({
  profileLink,
  notificationCount,
  isLoggedIn,
}: {
  profileLink: string | null;
  notificationCount: number;
  isLoggedIn: boolean;
}) => (
  <Box
    color={Colors.Black3}
    width="100%"
    style={{
      position: "fixed",
      bottom: 0,
      zIndex: 100,
    }}
    center
  >
    <Box width="85%">
      <Box
        spacing={{
          left: 30,
          right: 30,
        }}
      >
        <HStack spaceBetween center>
          <Link to={RouteName.Home}>
            <Box spacing={20}>
              <Icon size={23} type={IconType.Home} />
            </Box>
          </Link>
          <Link to={RouteName.Explore}>
            <Box spacing={20}>
              <Icon size={23} type={IconType.Hashtag} />
            </Box>
          </Link>
          <Link to={RouteName.Notifications}>
            <Box spacing={20}>
              <Box style={{ position: "relative" }}>
                <Icon size={23} type={IconType.Bell} />
                {notificationCount ? (
                  <Box
                    style={{
                      position: "absolute",
                      top: -5,
                      right: -5,
                      width: 10,
                      height: 10,
                      borderRadius: 100,
                      borderColor: Colors.Black3,
                      borderWidth: 5,
                      borderStyle: "solid",
                      backgroundColor: Colors.Pink,
                      zIndex: 100,
                    }}
                  />
                ) : null}
              </Box>
            </Box>
          </Link>
          <Link to={isLoggedIn && profileLink ? profileLink : RouteName.Home}>
            <Box spacing={20}>
              <Icon size={23} type={IconType.Profile} />
            </Box>
          </Link>
        </HStack>
      </Box>
    </Box>
  </Box>
);

const DesktopNavComponent = ({
  profileLink,
  isLoggedIn,
  notificationCount,
  headerParams,
}: {
  profileLink: string | null;
  isLoggedIn?: boolean;
  notificationCount: number;
  headerParams?: {
    leftButton?: {
      icon: IconType;
      onClick: () => void;
    };
    rightButton?: {
      icon: IconType;
      onClick: () => void;
    };
    title?: string;
  } | null;
}) => (
  <Box spacing={20}>
    <HStack center spaceBetween>
      <Box
        color={Colors.Black1}
        spacing={{
          left: 25,
          right: 15,
          top: 10,
          bottom: 10,
        }}
        borderRadius={20}
        style={{
          zIndex: 100,
        }}
      >
        <HStack center spaceBetween>
          <HStack center>
            <YgraPurpleGlowLogo
              style={{
                width: 30,
              }}
            />
            <Spacer x={10} />
            <Box width={90}>
              <Text text="ゆらぐら" bold />
            </Box>
          </HStack>
          <Spacer x={10} />
          <Link to={RouteName.Home}>
            <Box spacing={10}>
              <Icon size={25} type={IconType.Home} />
            </Box>
          </Link>
          <Link to={RouteName.Explore}>
            <Box spacing={10}>
              <Icon size={25} type={IconType.Hashtag} />
            </Box>
          </Link>
          <Link to={RouteName.Notifications}>
            <Box spacing={10}>
              <Box style={{ position: "relative" }}>
                <Icon size={25} type={IconType.Bell} />
                {notificationCount ? (
                  <Box
                    style={{
                      position: "absolute",
                      top: -5,
                      right: -5,
                      width: 10,
                      height: 10,
                      borderRadius: 100,
                      borderColor: Colors.Black1,
                      borderWidth: 5,
                      borderStyle: "solid",
                      backgroundColor: Colors.Pink,
                      zIndex: 100,
                    }}
                  />
                ) : null}
              </Box>
            </Box>
          </Link>
          <Link to={isLoggedIn && profileLink ? profileLink : RouteName.Home}>
            <Box spacing={10}>
              <Icon size={25} type={IconType.Profile} />
            </Box>
          </Link>
          {isLoggedIn && (
            <Link to={"/create-post"}>
              <Box spacing={10}>
                <Icon size={25} type={IconType.Add} />
              </Box>
            </Link>
          )}
        </HStack>
      </Box>

      <Box width="100%">
        <HStack spaceBetween>
          <HStack>
            <Spacer x={20} />
            <Touchable onPress={() => headerParams?.leftButton?.onClick()}>
              <Box
                color={Colors.Black1}
                height={45}
                width={45}
                borderRadius={20}
                style={{
                  marginLeft: headerParams?.leftButton ? 0 : -200,
                  opacity: headerParams?.leftButton ? 0.8 : 0,
                  transition: "all 0.5s ease-in-out",
                }}
                center
              >
                <HStack center>
                  <Icon
                    size={25}
                    type={headerParams?.leftButton?.icon || IconType.Left}
                  />
                </HStack>
              </Box>
            </Touchable>
          </HStack>

          <Touchable onPress={() => headerParams?.rightButton?.onClick()}>
            <Box
              color={Colors.Black1}
              height={45}
              width={45}
              borderRadius={20}
              style={{
                marginRight: headerParams?.rightButton ? 0 : -200,
                opacity: headerParams?.rightButton ? 0.8 : 0,
                transition: "all 0.5s ease-in-out",
              }}
              center
            >
              <HStack center>
                <Icon
                  size={25}
                  type={headerParams?.rightButton?.icon || IconType.TripleDot}
                />
              </HStack>
            </Box>
          </Touchable>
        </HStack>
      </Box>
    </HStack>
  </Box>
);

const styles: { [key: string]: React.CSSProperties } = {
  header: {
    display: "flex",
    top: 0,
    backgroundColor: Colors.Black3,
    height: 65,
    position: "fixed",
    width: "100%",
    justifyContent: "center",
    zIndex: 100,
  },
  headerContainer: {
    alignContent: "center",
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    justifyContent: "space-between",

    width: "90%",
    flexDirection: "row",
  },
  icon: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
  },
  title: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
  },
};

const HeaderComponent = ({
  leftButton,
  rightButton,
  title,
}: {
  leftButton?: {
    icon: IconType;
    onClick: () => void;
  };
  rightButton?: {
    icon: IconType;
    onClick: () => void;
  };
  title?: string;
}) => (
  <Box style={styles.header}>
    <Box style={styles.headerContainer}>
      {leftButton ? (
        <Box style={styles.icon}>
          <Touchable onPress={leftButton.onClick}>
            <Icon size={25} type={leftButton.icon} />
          </Touchable>
        </Box>
      ) : (
        <Box width={25} height={40} />
      )}

      {title ? (
        <Box style={styles.title}>
          <Text text={title} />
        </Box>
      ) : null}
      {rightButton ? (
        <Box style={styles.icon}>
          <Touchable onPress={rightButton.onClick}>
            <Icon size={25} type={rightButton.icon} />
          </Touchable>
        </Box>
      ) : (
        <Box width={25} height={40} />
      )}
    </Box>
  </Box>
);
