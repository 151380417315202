// import {Asset} from 'react-native-image-picker';

export enum PostContentType {
  String = "string",
  Image = "image",
  RePost = "repost",
}

export type TextContent = {
  type: PostContentType.String;
  content: string;
};

export type ImageContent = {
  type: PostContentType.Image;
  imageUrl: string;
  params?: {
    width: number;
    height: number;
    color: string;
  };
};

export type PostContent = TextContent | ImageContent;

export type PostFormTextContent = {
  type: PostContentType.String;
  content: string;
};

export type PostFormImageContent = {
  type: PostContentType.Image;
  image: any;
  id: number;
};

export type PostFormContent = PostFormTextContent | PostFormImageContent;

const imagePattern =
  /\[image:\((.*?)\)(?:, width: (\d+))?(?:, height: (\d+))?(?:, color: (#\w+))?\]/g;

export const postContentSplit = (text: string): PostContent[] => {
  const result: PostContent[] = [];
  let lastIndex = 0;

  let match;
  while ((match = imagePattern.exec(text)) !== null) {
    const textBeforeImage = text?.substring(lastIndex, match.index).trim();
    if (textBeforeImage.length > 0) {
      result.push({
        type: PostContentType.String,
        content: textBeforeImage,
      });
    }

    const imageUrl = match[1];
    const width = match[2] ? Number(match[2]) : undefined;
    const height = match[3] ? Number(match[3]) : undefined;
    const color = match[4] || undefined;

    const params =
      width && height && color ? { width, height, color } : undefined;

    const mageContent: ImageContent = {
      type: PostContentType.Image,
      imageUrl,
      params,
    };
    result.push(mageContent);

    lastIndex = imagePattern.lastIndex;
  }

  const remainingText = text?.substring(lastIndex).trim();
  if (remainingText.length > 0) {
    const textContent: TextContent = {
      type: PostContentType.String,
      content: remainingText,
    };
    result.push(textContent);
  }

  return result;
};
