import React from "react";

const SaveIconWhite = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M3 2V11L6.5 8.59155L10 11V2H3Z"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export default SaveIconWhite;
