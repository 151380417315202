import React from "react";

export const HStack = ({
  children,
  space,
  center,
  spaceBetween,
  style,
}: {
  children: React.ReactNode;
  space?: number;
  center?: boolean;
  spaceBetween?: boolean;
  style?: React.CSSProperties;
}) => {
  const basicStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",

    ...(space
      ? {
          marginVertical: space,
        }
      : {}),
    ...(center
      ? {
          alignItems: "center",
        }
      : {}),
    ...(spaceBetween
      ? {
          justifyContent: "space-between",
        }
      : {}),
  };

  return <div style={{ ...basicStyle, ...style }}>{children}</div>;
};
