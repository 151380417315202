import React from "react";

const HashtagIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M5.23077 1L3.53846 11M9.46154 1L7.76923 11M1.84615 3.72727H12M11.1538 7.81818H1"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HashtagIcon;
