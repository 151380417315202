import { Post } from "./types/Post";
import { User } from "./types/User";

import {
  apiCreatePost,
  apiCreateSave,
  apiDeletePost,
  apiDeleteSave,
  apiGetPost,
  apiGetPostReplies,
  apiGetPostSaves,
  apiGetPosts,
  apiGetPostsFromFollowingUsers,
  apiGetPostsFromIds,
  apiGetPostsFromSearch,
  apiGetTagPosts,
  apiGetTagsPosts,
  apiGetUserPosts,
  apiGetUserReplies,
  apiGetUserSaves,
  apiUndoRePost,
} from "./API/post";
import {
  apiAuthoriseEmail,
  apiDeleteUser,
  apiGetUserByHangName,
  apiGetUserById,
  apiGetUsersFromSearch,
  apiUpdateHangName,
  apiSendAuthorisationEmail,
  apiUpdateNsfw,
  apiUpdateUser,
} from "./API/user";
import {
  apiCreateFollow,
  apiDeleteFollow,
  apiGetUserFollows,
} from "./API/follow";
import {
  apiCreateBlock,
  apiDeleteBlock,
  apiGetBlocks,
  apiGetUserIsBlocked,
} from "./API/block";
import {
  apiGetNotificationCount,
  apiGetNotifications,
} from "./API/notification";
import {
  apiGetPopularTags,
  apiGetSuggestedTags,
  apiGetTag,
  apiGetTagFromSearch,
} from "./API/tag";
import { Notification } from "./types/Notification";
import { Report, apiCreateReport } from "./API/report";
import { Tag } from "./types/Tag";
import {
  apiCreateSavedTag,
  apiGetSavedTags,
  apiRemoveSavedTag,
} from "./API/tagsSaved";

interface API {
  posts: {
    getPopularPosts: (offset?: number) => Promise<Post[]>;
    getPostsFromFollowingUsers: (offset?: number) => Promise<Post[]>;
    getPostsFromIds: (ids: string[]) => Promise<Post[]>;
    getTagPosts: (tags: string, offset?: number) => Promise<Post[]>;
    getTagsPosts: (tags: string, offset?: number) => Promise<Post[]>;
    getUserPosts: (id: User["id"], offset?: number) => Promise<Post[]>;
    getPostsFromSearch: (id: User["id"], offset?: number) => Promise<Post[]>;
    getPost: (id: Post["id"]) => Promise<Post>;
    getPostReplies: (id: Post["id"], offset?: number) => Promise<Post[]>;
    getPostSaves: (id: Post["id"], offset?: number) => Promise<Post[]>;
    createPost: (req: FormData) => Promise<Post>;
    deletePost: (id: Post["id"]) => Promise<any>;

    createSave: (id: Post["id"]) => Promise<User>;
    deleteSave: (id: Post["id"]) => Promise<User>;
    getUserSaves: (id: User["id"], offset?: number) => Promise<Post[]>;
    undoRePost: (id: Post["id"]) => Promise<any>;
    getUserReplies: (offset?: number) => Promise<Post[]>;
  };
  users: {
    getUserById: (id: User["id"]) => Promise<User>;
    getUserByHangName: (hangName: User["hangName"]) => Promise<User>;
    updateUser: (userUpdate: FormData) => Promise<User>;
    getUsersFromSearch: (search: string) => Promise<User[]>;
    updateHangName: (hangName: string) => Promise<User>;
    authoriseEmail: (code: string) => Promise<User>;
    apiSendAuthorisationEmail: () => Promise<any>;
    deleteUser: () => Promise<any>;
    updateNsfw: (nsfw: boolean) => Promise<User>;
  };
  follows: {
    createFollow: (id: User["id"]) => Promise<any>;
    deleteFollow: (id: User["id"]) => Promise<any>;
    getUserFollows: (id: User["id"]) => Promise<User[]>;
  };
  blocks: {
    createBlock: (id: User["id"]) => Promise<any>;
    deleteBlock: (id: User["id"]) => Promise<any>;
    getBlocks: () => Promise<User[]>;
    getUserIsBlocked: (id: User["id"]) => Promise<boolean>;
  };
  notifications: {
    getNotificationCount: () => Promise<number>;
    getNotifications: () => Promise<Notification[]>;
  };
  tags: {
    getTag: (tag: string) => Promise<Tag>;
    getSuggestedTags: () => Promise<Tag[]>;
    getPopularTags: () => Promise<Tag[]>;
    getTagFromSearch: (tag: string) => Promise<Tag[]>;
  };
  tagsSaved: {
    getTagsSaved: () => Promise<Tag[]>;
    createTagSaved: (tag: Tag["tag"]) => Promise<Tag>;
    removeTagSaved: (tag: Tag["tag"]) => Promise<Tag>;
  };
  reports: {
    createReport: (report: Report) => Promise<any>;
  };
}

export interface AuthProxy {
  onAuthStateChanged: (callback: (fireBaseUser: any | null) => void) => void;
}

export class YGRA4Service {
  token: string | null = null;
  state: {
    user: User | null;
    posts: Post[];
    users: User[];
    tags: Tag[];

    update: {
      posts: (posts: Post[]) => void;
      users: (users: User[]) => void;
      user: (user: User) => void;
      tags: (tags: Tag[]) => void;
    };

    clear: () => void;
  };

  private static instance: YGRA4Service;
  public constructor() {
    this.state = {
      user: null,
      posts: [],
      users: [],
      tags: [],

      update: {
        posts: (posts) => {
          this.state.posts = posts;
        },
        users: (users) => {
          this.state.users = users;
        },
        user: (user) => {
          this.state.user = user;
        },
        tags: (tags) => {
          this.state.tags = tags;
        },
      },

      clear: () => {
        this.state.user = null;
        this.state.posts = [];
        this.state.users = [];
        this.state.tags = [];
      },
    };
  }

  static getInstance() {
    if (!YGRA4Service.instance) {
      YGRA4Service.instance = new YGRA4Service();
    }
    return YGRA4Service.instance;
  }

  public configure = ({ auth }: { auth: AuthProxy }) => {
    auth.onAuthStateChanged(async (auth) => {
      if (auth) {
        const token = await auth.getIdToken();
        this.token = token;

        this.api()
          .users.getUserById(auth.uid)
          .then((user) => {
            this.state.user = user;
          });
      }
    });
  };

  public updateToken = (token: string) => {
    this.token = token;
  };

  refetchUser = async () => {
    if (this.token) {
      return await this.api()
        .users.getUserById(this.state.user?.id || "")
        .then((user) => {
          this.state.user = user;
        });
    }
  };

  public api = (): API => {
    return {
      posts: {
        getPopularPosts: apiGetPosts(this.token),
        getPostsFromFollowingUsers: apiGetPostsFromFollowingUsers(this.token),
        getPostsFromIds: apiGetPostsFromIds(this.token),
        getTagPosts: apiGetTagPosts(this.token),
        getTagsPosts: apiGetTagsPosts(this.token),
        getUserPosts: apiGetUserPosts(this.token),
        getPostsFromSearch: apiGetPostsFromSearch(this.token),
        getPost: apiGetPost(this.token),
        getPostReplies: apiGetPostReplies(this.token),
        getPostSaves: apiGetPostSaves(this.token),
        createPost: apiCreatePost(this.token),
        deletePost: apiDeletePost(this.token),
        createSave: apiCreateSave(this.token),
        deleteSave: apiDeleteSave(this.token),
        getUserSaves: apiGetUserSaves(this.token),
        undoRePost: apiUndoRePost(this.token),
        getUserReplies: apiGetUserReplies(this.token),
      },
      users: {
        getUserById: apiGetUserById(this.token),
        getUserByHangName: apiGetUserByHangName(this.token),
        updateUser: apiUpdateUser(this.token),
        getUsersFromSearch: apiGetUsersFromSearch(this.token),
        updateHangName: apiUpdateHangName(this.token),
        authoriseEmail: apiAuthoriseEmail(this.token),
        apiSendAuthorisationEmail: apiSendAuthorisationEmail(this.token),
        deleteUser: apiDeleteUser(this.token),
        updateNsfw: apiUpdateNsfw(this.token),
      },
      follows: {
        createFollow: apiCreateFollow(this.token),
        deleteFollow: apiDeleteFollow(this.token),
        getUserFollows: apiGetUserFollows(this.token),
      },
      blocks: {
        createBlock: apiCreateBlock(this.token),
        deleteBlock: apiDeleteBlock(this.token),
        getBlocks: apiGetBlocks(this.token),
        getUserIsBlocked: apiGetUserIsBlocked(this.token),
      },
      notifications: {
        getNotificationCount: apiGetNotificationCount(this.token),
        getNotifications: apiGetNotifications(this.token),
      },
      tags: {
        getTag: apiGetTag(this.token),
        getSuggestedTags: apiGetSuggestedTags(this.token),
        getPopularTags: apiGetPopularTags(this.token),
        getTagFromSearch: apiGetTagFromSearch(this.token),
      },
      tagsSaved: {
        getTagsSaved: apiGetSavedTags(this.token),
        createTagSaved: apiCreateSavedTag(this.token),
        removeTagSaved: apiRemoveSavedTag(this.token),
      },
      reports: {
        createReport: apiCreateReport(this.token),
      },
    };
  };
}

export default YGRA4Service.getInstance();
