import {serverURL} from '../consts/serverURL';
import {System} from '../types/System';
import {User} from '../types/User';

export const apiGetUserFollows =
  (token: System['token']) => async (id: User['id']) => {
    const data = await fetch(`${serverURL}/follows/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
    }).then(res => res.json());

    return data as User[];
  };

export const apiCreateFollow =
  (token: System['token']) => async (id: User['id']) =>
    await fetch(`${serverURL}/follows`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
      body: JSON.stringify({id}),
    }).then(res => res.json());

export const apiDeleteFollow =
  (token: System['token']) => async (id: User['id']) =>
    await fetch(`${serverURL}/follows`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
      body: JSON.stringify({id}),
    }); // .then((res) => res.json());
