import React from "react";

const ReplyIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M3.5 2H9.5C10.0523 2 10.5 2.44772 10.5 3V6.5V11L7.5 8.5H3.5C2.94772 8.5 2.5 8.05228 2.5 7.5V3C2.5 2.44772 2.94772 2 3.5 2Z"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReplyIcon;
