import {serverURL} from '../consts/serverURL';
import {System} from '../types/System';
import {Tag} from '../types/Tag';

export const apiGetSavedTags = (token: System['token']) => async () => {
  const data = await fetch(`${serverURL}/tags/save`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: String(token),
    },
  }).then(res => res.json());

  return data as Tag[];
};

export const apiCreateSavedTag =
  (token: System['token']) => async (tag: string) => {
    const data = await fetch(`${serverURL}/tags/save`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
      body: JSON.stringify({tag}),
    }).then(res => res.json());

    return data as any;
  };

export const apiRemoveSavedTag =
  (token: System['token']) => async (tag: string) => {
    const data = await fetch(`${serverURL}/tags/save`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
      body: JSON.stringify({tag}),
    }).then(res => res.json());

    return data as any;
  };
