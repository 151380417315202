import {serverURL} from '../consts/serverURL';
import {System} from '../types/System';
import {Tag} from '../types/Tag';

export const apiGetSuggestedTags = (token: System['token']) => async () => {
  const data = await fetch(`${serverURL}/tags/suggested`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: String(token),
    },
  }).then(res => res.json());

  return data as any;
};

export const apiGetTagFromSearch =
  (token: System['token']) => async (search: string | undefined) => {
    const data = await fetch(`${serverURL}/tags/search/${search}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
    }).then(res => res.json());

    return data as Tag[];
  };

export const apiGetPopularTags = (token: System['token']) => async () => {
  const data = await fetch(`${serverURL}/tags/popular`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: String(token),
    },
  }).then(res => res.json());

  return data as Tag[];
};

export const apiGetTag = (token: System['token']) => async (tag: string) => {
  const data = await fetch(`${serverURL}/tags/${tag}/details`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: String(token),
    },
  }).then(res => res.json());

  return data as Tag;
};
