import React from "react";

const TripleLineIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M3 4L10 4"
      stroke="white"
      stroke-width="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 7L10 7"
      stroke="white"
      stroke-width="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 10L10 10"
      stroke="white"
      stroke-width="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TripleLineIcon;
