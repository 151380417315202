import service from '../service';
import {Notification} from '../types/Notification';
import {useCreateQueryHook} from './constructors/useCreateQueryHook';

export const useGetNotifications = () =>
  useCreateQueryHook<Notification[]>(
    service.api().notifications.getNotifications,
    true,
  );

export const useGetNotificationCount = () =>
  useCreateQueryHook<number>(
    service.api().notifications.getNotificationCount,
    true,
  );
