import React from "react";

export const useScrollEnd = (offset: number = 0) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [isAtEnd, setIsAtEnd] = React.useState(false);

  const onScroll = React.useCallback(() => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      const isEndReached = scrollTop + clientHeight >= scrollHeight - offset;

      setIsAtEnd(isEndReached);
    }
  }, [offset]);

  React.useEffect(() => {
    const currentElement = ref.current;
    if (currentElement) {
      currentElement.addEventListener("scroll", onScroll);
    }

    return () => {
      if (currentElement) {
        currentElement.removeEventListener("scroll", onScroll);
      }
    };
  }, [onScroll]);

  return { ref, isAtEnd };
};
