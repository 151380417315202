import React from "react";
import { Colors } from "../../../Mono/consts/Colors";

export enum TextVariants {
  Larger = 24,
  Regular = 18,
  Small = 16,
  Smaller = 14,
}

export const Text = ({
  text,
  children,

  color = Colors.White,
  size = TextVariants.Regular,
  bold,
  styles,
}: {
  text?: string | number;
  children?: React.ReactNode;

  color?: Colors;
  size?: TextVariants;
  bold?: boolean;
  styles?: React.CSSProperties;
}) => {
  const basicStyle: React.CSSProperties = {
    color,
    fontSize: size,
    fontWeight: bold ? "bold" : "normal",
    margin: 0,
  };

  return <p style={{ ...basicStyle, ...styles }}>{children || text}</p>;
};
