import { serverURL } from "../consts/serverURL";
import { System } from "../types/System";
import { User } from "../types/User";

export const apiGetUserById =
  (token: System["token"]) => async (id: User["id"] | undefined) => {
    const data = await fetch(`${serverURL}/users/id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: String(token),
      },
    }).then((res) => res.json());

    return data as User;
  };

export const apiGetUserByHangName =
  (token: System["token"]) =>
  async (hangName: User["hangName"] | undefined) => {
    const data = await fetch(`${serverURL}/users/${hangName}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: String(token),
      },
    }).then((res) => res.json());

    return data as User;
  };

export interface UserUpdate {
  name: User["name"];
  avatar: User["avatar"];
  background: User["background"];
}

export const apiUpdateUser =
  (token: System["token"]) => async (formData: FormData) => {
    const data = await fetch(`${serverURL}/users/update`, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      referrerPolicy: "no-referrer",
      headers: {
        Authorization: String(token),
      },
      body: formData,
    }).then((res) => res.json());

    return data as User;
  };

export const apiUpdateHangName =
  (token: System["token"]) => async (hangName: User["hangName"]) => {
    const data = await fetch(`${serverURL}/users/update/hangname`, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: String(token),
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ hangName }),
    }).then((res) => res.json());

    return data as User;
  };

export const apiAuthoriseEmail =
  (token: System["token"]) => async (authenticationCode: string) => {
    const data = await fetch(`${serverURL}/users/authorise-email`, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: String(token),
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ authenticationCode }),
    }).then((res) => res.json());

    return data as User;
  };

export const apiSendAuthorisationEmail =
  (token: System["token"]) => async () => {
    const data = await fetch(`${serverURL}/users/authorise-email/resend`, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: String(token),
      },
      referrerPolicy: "no-referrer",
    }).then((res) => res.json());

    return data as User;
  };

export const apiDeleteUser = (token: System["token"]) => async () => {
  const data = await fetch(`${serverURL}/users/delete`, {
    method: "DELETE",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: String(token),
    },
    referrerPolicy: "no-referrer",
  }).then((res) => res.json());

  return data as User;
};

export const apiGetUsersFromSearch =
  (token: System["token"]) => async (search: string | undefined) => {
    const data = await fetch(`${serverURL}/users/search/${search}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: String(token),
      },
    }).then((res) => res.json());

    return data as User[];
  };

export const apiUpdateNsfw =
  (token: System["token"]) => async (nsfw: User["nsfw"]) => {
    const data = await fetch(`${serverURL}/users/update/nsfw`, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: String(token),
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ nsfw }),
    }).then((res) => res.json());

    return data as User;
  };
