export function stringToColor(str: string) {
  // Generate a hash code from the input string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // Simple hash function: shift and sum character codes
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash code to an RGB color
  let color = "#";
  for (let i = 0; i < 3; i++) {
    // Extract each color component by shifting
    const value = (hash >> (i * 8)) & 0xff;
    // Convert component to hex and pad with zeros if necessary
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}
