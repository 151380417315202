import React from "react";
import { Icon, IconType } from "../../assets/icons";
import { Colors } from "../../../Mono/consts/Colors";
import { Box } from "../UI/Box";
import { HStack } from "../UI";

export const CircleButton = ({ type }: { type?: IconType }) => (
  <Box width={60} height={60} borderRadius={120} center color={Colors.Black1}>
    <HStack center>
      <Icon size={23} type={type || IconType.Add} />
    </HStack>
  </Box>
);
