import React from "react";

const SearchIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg style={style} viewBox="0 0 16 16" fill="none">
    <circle cx="7.0167" cy="7.0167" r="6.0167" stroke="white" strokeWidth="2" />
    <path
      d="M14.0307 15.4449C14.4213 15.8354 15.0544 15.8354 15.445 15.4449C15.8355 15.0544 15.8355 14.4212 15.445 14.0307L14.0307 15.4449ZM10.0307 11.4449L14.0307 15.4449L15.445 14.0307L11.445 10.0307L10.0307 11.4449Z"
      fill="white"
    />
  </svg>
);

export default SearchIcon;
