import service from '../service';
import {Tag} from '../types/Tag';
import {useCreateQueryHook} from './constructors/useCreateQueryHook';

export const getTagCheckCacheByHangName = async (tag: Tag['tag']) => {
  const tagDetails = service.state.tags?.find(t => t.tag === tag);

  if (tagDetails) {
    return tagDetails;
  }

  return service.api().tags.getTag(tag);
};

export const useGetTag = (tag: string) =>
  useCreateQueryHook<Tag>(getTagCheckCacheByHangName, tag);

export const useGetTagsFromSearch = (search: string) =>
  useCreateQueryHook<Tag[]>(service.api().tags.getTagFromSearch, search);

export const useGetSuggestedTags = () =>
  useCreateQueryHook<Tag[]>(service.api().tags.getSuggestedTags, true);

export const useGetPopularTags = () =>
  useCreateQueryHook<Tag[]>(service.api().tags.getPopularTags, true);

export const useGetTagsSaved = () =>
  useCreateQueryHook<Tag[]>(service.api().tagsSaved.getTagsSaved, true);
