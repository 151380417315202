import React from "react";

export const Touchable = ({
  children,
  onPress,

  enableOpacity,
}: {
  children: React.ReactNode;
  onPress: () => void;

  enableOpacity?: boolean;
}) => {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onPress();
      }}
      style={{ opacity: enableOpacity ? 0.8 : 1, cursor: "pointer" }}
    >
      {children}
    </div>
  );
};
