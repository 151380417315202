import React from "react";
import { Colors } from "../../../Mono/consts/Colors";
import { Box } from "../UI/Box";

export const NotificationsLoading = () => (
  <Box>
    <Box
      flex
      height={100}
      color={Colors.Black1}
      spacing={5}
      borderRadius={15}
    />
    <Box height={10} />
    <Box flex height={60} color={Colors.Black1} spacing={5} borderRadius={15} />
    <Box height={10} />
    <Box
      flex
      height={120}
      color={Colors.Black1}
      spacing={5}
      borderRadius={15}
    />
  </Box>
);
