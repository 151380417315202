import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Box, Spacer } from "../../Components/UI";

export const Terms = () => {
  const mode = useGetWindowMode();

  return (
    <Box>
      <Spacer y={mode === "web" ? 120 : 20} />
      <Box center>
        <Box
          style={{
            maxWidth: 630,

            overflow: "hidden",
            margin: 0,
            height: "auto",
            width: "100%",
            padding: 20,
          }}
        >
          <div style={{ color: "white" }}>
            <div>
              <div>
                <div>
                  <div
                    data-message-author-role="assistant"
                    data-message-id="056630f0-9ab6-4bc1-acd3-54b301edc289"
                  >
                    <div>
                      <h1>ゆらぐら 利用規約</h1>
                      <p>
                        <strong>ゆらぐらへようこそ</strong>
                        <br />
                        <br />
                        ゆらぐらは、互いに投稿を共有し、コミュニティを作るためのソーシャルメディアアプリです。当サービスにアクセスまたは利用することにより、以下に概説された利用規約に拘束されることに同意したことになります。当サービスを利用する前に、これらの規約を慎重にお読みください。
                      </p>
                      <h4>利用規約の受諾</h4>
                      <ul>
                        <li>
                          ゆらぐらを利用することにより、これらの利用規約を受諾し、これに従うことに同意したことになります。
                        </li>
                        <li>
                          これらの規約に同意しない場合は、当サービスを利用してはなりません。
                        </li>
                      </ul>
                      <h4>規約の変更</h4>
                      <ul>
                        <li>
                          ゆらぐらは、いつでも自己の裁量によりこれらの規約を変更または置き換える権利を有します。
                        </li>
                        <li>
                          重要な変更については通知を提供し、サービスの継続的な利用を変更された規約の受諾とみなします。
                        </li>
                      </ul>
                      <h4>ユーザーアカウントと責任</h4>
                      <ul>
                        <li>
                          ユーザーは自分のアカウント情報の機密性を維持する責任があり、アカウントを通じて行われるすべての活動に対して責任を負います。
                        </li>
                        <li>
                          ユーザーは、アカウントの不正使用が判明した場合には、直ちにゆらぐらに通知することに同意します。
                        </li>
                      </ul>
                      <h4>禁止活動</h4>
                      <p>
                        ゆらぐらのユーザーは、以下の活動を行ってはなりません：
                      </p>
                      <ul>
                        <li>ポルノコンテンツの投稿、共有、または促進。</li>
                        <li>虐待、嫌がらせ、またはサイバーブリングに従事。</li>
                        <li>
                          機密コンテンツの拡散、誤情報の提供、または詐欺活動に従事。
                        </li>
                        <li>他人のなりすましまたは知的財産権の侵害。</li>
                        <li>
                          適切なライセンスなしにコンテンツをアップロード。
                        </li>
                      </ul>
                      <h4>データ取り扱い</h4>
                      <ul>
                        <li>
                          ゆらぐらは、ユーザーデータを安全なサーバーに保存します。
                        </li>
                        <li>
                          法律に要求されない限り、ユーザーの同意なしに第三者とデータを共有することはありません。
                        </li>
                      </ul>
                      <h4>違反の報告</h4>
                      <ul>
                        <li>
                          ユーザーは、ガイドラインに違反するコンテンツを報告することが奨励されています。
                        </li>
                        <li>
                          ゆらぐらは、これらの規約に違反するコンテンツを削除し、ポリシーに違反するユーザーを禁止する権利を有します。
                        </li>
                      </ul>
                      <h4>権利と執行</h4>
                      <ul>
                        <li>
                          ゆらぐらは、規約に反するユーザーや投稿を禁止し、削除する権利を有します。
                        </li>
                        <li>
                          当社の規約に違反したユーザーは、一時的または永久的な禁止につながる三回のストライクルールの対象となる場合があります。
                        </li>
                      </ul>
                      <h4>NSFWコンテンツ</h4>
                      <ul>
                        <li>
                          当サービスでは、ユーザーがWebアプリケーションの設定を通じて、NSFW（成人向け）コンテンツの表示を選択できるオプションを提供しています。この機能により、裸体を含むコンテンツの閲覧が可能になりますが、デフォルト設定では無効化されています。
                        </li>
                        <li>
                          この設定の有効化は、Webインターフェースを通じてのみ可能であり、モバイルアプリケーション等他のプラットフォームではアクセスできません。これは、ユーザー体験を最適化し、プラットフォームごとの使用環境を考慮した結果です。
                        </li>
                        <li>
                          重要なこととして、このオプションを有効にした場合であっても、当サービスの基本的な規範とポリシーは引き続き適用されます。具体的には、虐待、いじめ、その他の利用規約で定められた制限行為に対する禁止が維持されます。これは、全てのユーザーに対して安全でポジティブなコンテンツ環境を提供するためのものです。
                        </li>
                        <li>
                          当社では、ユーザーが自己の判断でコンテンツの閲覧を選択できるようにしつつも、サービス全体の品質とセキュリティを維持することを重視しています。不適切なコンテンツや行為に対しては、迅速に対処し、当サービスのガイドラインに違反するユーザーには適切な措置を講じます。
                        </li>
                      </ul>
                      <p>
                        この方針は、ユーザーの権利と安全を保護するため、そして全ての人が尊重されるコミュニティ環境を築くために不可欠です。当サービスを利用するすべての方が、これらの規定に留意し、共に健全なサービス利用を心がけていただけますようお願い申し上げます。
                      </p>
                      <h4>紛争解決</h4>
                      <ul>
                        <li>
                          ゆらぐらは、他の解決策を求める前に、まず直接当社に連絡して問題を解決するようユーザーに促します。
                        </li>
                      </ul>
                      <h4>分離可能性</h4>
                      <ul>
                        <li>
                          これらの規約のいずれかの条項が無効または執行不能と判断された場合でも、残りの条項は完全な効力を持続します。
                        </li>
                      </ul>
                      <h4>連絡先情報</h4>
                      <ul>
                        <li>
                          これらの規約についての質問や懸念がある場合は、ゆらぐらまでお問い合わせください。
                        </li>
                      </ul>
                      <p>
                        <strong>
                          ゆらぐらを利用することにより、これらの利用規約を読み、理解し、これに拘束されることに同意したことを認めます。
                        </strong>
                      </p>
                      <ul>
                        <li>
                          ゆらぐらは、これらの規約に違反するユーザーのアクセスを終了する権利を有し、すべてのユーザーに安全で尊重される環境を保証します。
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box height={120} />
        </Box>
      </Box>
    </Box>
  );
};
