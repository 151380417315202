import service from '../service';
import {User} from '../types/User';
import {useCreateQueryHook} from './constructors/useCreateQueryHook';

export const updateUserCache = (users: User[]) => {
  const newUsers = users.filter(
    user => !service.state.users?.map(p => p.id).includes(user.id),
  );
  const updatedUsers = users.filter(user =>
    service.state.users?.map(p => p.id).includes(user.id),
  );

  const newCachedUsers = [...(service.state.users || []), ...newUsers].map(
    p => {
      const updateUser = updatedUsers.find(up => up.id === p.id);
      if (updateUser) {
        return updateUser;
      }
      return p;
    },
  );

  service.state.update.users(newCachedUsers);
};

export const getUserCheckCacheByHangName = async (
  hangName: User['hangName'],
) => {
  const user = service.state.users?.find(p => p.hangName === hangName);

  if (user) {
    return user;
  }

  return service.api().users.getUserByHangName(hangName);
};

export const getUserCheckCacheById = async (id: User['id']) => {
  const user = service.state.users?.find(p => p.id === id);

  if (user) {
    return user;
  }

  return service.api().users.getUserById(id);
};

export const useGetUserByHangName = (hangName: User['hangName']) =>
  useCreateQueryHook<User>(getUserCheckCacheByHangName, hangName);

export const useGetUserById = (id: User['id']) =>
  useCreateQueryHook<User>(getUserCheckCacheById, id);

export const useGetUsersFromSearch = (search: string) =>
  useCreateQueryHook<User[]>(service.api().users?.getUsersFromSearch, search);
