import React from "react";

import { Box, HStack, Spacer, Text, Touchable, VStack } from "../Components/UI";

import { Icon, IconType } from "../assets/icons";
import { Colors } from "../../Mono/consts/Colors";

export const BottomSheet = ({
  children,
  display,
  closeModal,
}: {
  children: React.ReactNode;
  display: boolean;
  closeModal: () => void;
}) => {
  const basicOverlayStyle: React.CSSProperties = {
    backgroundColor: Colors.Black3,
    opacity: 0.5,
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
    zIndex: 100,
    display: display ? "flex" : "none",
  };

  const modalStyle: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    maxWidth: 700,

    bottom: 0,
    backgroundColor: Colors.Black1,
    zIndex: 100,
    display: display ? "flex" : "none",
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    flexDirection: "column",
  };

  return (
    <>
      <Touchable onPress={closeModal}>
        <Box style={basicOverlayStyle} />
      </Touchable>
      <Box center>
        <Box style={modalStyle}>
          <Touchable onPress={closeModal}>
            <Spacer y={20} />
            <VStack center>
              <Box
                width={50}
                height={5}
                borderRadius={10}
                color={Colors.Grey2}
              />
            </VStack>
            <Spacer y={20} />
          </Touchable>
          <VStack center>
            <Box
              color={Colors.Black2}
              height={1}
              flex
              width="95%"
              center
              borderRadius={90}
            />
          </VStack>
          <Spacer y={10} />
          {children}
        </Box>
      </Box>
    </>
  );
};

export const ButtonsList = ({
  postOptions,
}: {
  postOptions: {
    label: string;
    icon?: IconType;
    onPress: () => void;
  }[];
}) => (
  <>
    {postOptions.map((option) => (
      <Touchable onPress={option.onPress}>
        <Box
          key={option.label}
          spacing={{
            top: 20,
            bottom: 20,
            left: 30,
            right: 30,
          }}
        >
          <HStack center>
            {option.icon ? <Icon type={option.icon} size={20} /> : null}
            <Box width={10} />
            <Text color={Colors.White}>{option.label}</Text>
          </HStack>
        </Box>
      </Touchable>
    ))}
  </>
);
