import React from "react";

const BellIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M4.4375 9.18182V9.28125C4.4375 10.2305 5.20701 11 6.15625 11V11C7.10549 11 7.875 10.2305 7.875 9.28125V9.18182M2 7.81818L3.02959 3.69892C3.42604 2.11277 4.85116 1 6.48612 1V1C8.11268 1 9.53275 2.10161 9.93712 3.67711L11 7.81818H2Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BellIcon;
