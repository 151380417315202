import { MasonryComponent } from "../../Components/UX/Masonry";
import { useParams } from "react-router-dom";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { RouteName } from "../../../Routes";
import { PageContainer } from "../../Components/UX/Layout/PageContainer";
import { useGetTag, useGetTagPosts } from "../../../Mono/queryHooks";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import {
  Box,
  Button,
  HStack,
  Spacer,
  TextVariants,
  VStack,
  Text,
  ButtonVariants,
} from "../../Components/UI";
import { Colors } from "../../../Mono/consts/Colors";
import { Post } from "../../../Mono/types";
import service from "../../../Mono/service";
import { stringToColor } from "../../../Mono/tools/stringToColor";

export const Tag = () => {
  let { tag: tagId } = useParams();
  const mode = useGetWindowMode();

  const { val: tag, reload: refetchTag } = useGetTag(tagId || "");

  const onSaveTag = () =>
    tag
      ? tag?.isSaved
        ? service.api().tagsSaved.removeTagSaved(tag.tag).then(refetchTag)
        : service.api().tagsSaved.createTagSaved(tag.tag).then(refetchTag)
      : null;

  const { posts, getMore } = useGetTagPosts(tagId || "");

  const linkTo = useLinkTo();
  const onClickPost = (post: Post) => {
    linkTo(RouteName.Post, { postId: post.id });
  };

  const bannerColor = tag ? stringToColor(tag.tag) + "60" : Colors.Black1;

  return (
    <PageContainer onEndReached={getMore}>
      <Spacer y={mode === "web" ? 110 : 10} />
      <Box
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        <Box center>
          <Box
            spacing={
              mode === "web"
                ? { left: 15, right: 15 }
                : {
                    left: 10,
                    right: 10,
                  }
            }
            style={{ position: "relative", maxWidth: 1000, width: "100%" }}
          >
            <Box height={100} borderRadius={20} color={bannerColor} center>
              <HStack center>
                <Text color={Colors.White} bold size={20}>
                  #
                </Text>
                <Spacer x={5} />
                <Text color={Colors.White} bold size={20}>
                  {tagId}
                </Text>
              </HStack>
            </Box>
            <Spacer y={15} />
            <HStack center spaceBetween>
              <Box
                spacing={{
                  left: 20,
                }}
              >
                <Button
                  bold
                  center
                  text={tag?.isSaved ? "✓ 推し" : "+ 推しに追加"}
                  variant={
                    tag?.isSaved ? ButtonVariants.Dark : ButtonVariants.Pink
                  }
                  onPress={onSaveTag}
                />
              </Box>

              <Box
                spacing={{
                  right: 10,
                }}
              >
                <HStack center>
                  <HStack>
                    <Text text={tag?.count} size={TextVariants.Small} />
                    <Spacer x={5} />
                    <Text
                      color={Colors.Grey}
                      text="投稿"
                      size={TextVariants.Small}
                    />
                  </HStack>
                  <Spacer x={15} />
                  <HStack>
                    <Text text={tag?.follows} size={TextVariants.Small} />
                    <Spacer x={5} />
                    <Text
                      color={Colors.Grey}
                      text="フォロー"
                      size={TextVariants.Small}
                    />
                    <Spacer x={10} />
                  </HStack>
                </HStack>
              </Box>
            </HStack>
          </Box>
        </Box>
        <Spacer y={15} />

        <Box center>
          <Box
            spacing={
              mode === "web"
                ? { left: 15, right: 15 }
                : {
                    left: 10,
                    right: 10,
                  }
            }
            style={{
              width: "100%",
              maxWidth: 1000,
              zIndex: 1,
            }}
          >
            {posts ? (
              <MasonryComponent posts={posts} onClick={onClickPost} />
            ) : null}
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};
