import React from "react";

export const Box = ({
  children,
  center,
  flex,
  color,
  borderRadius,
  spacing,
  width,
  height,

  style,
}: {
  children?: React.ReactNode;
  center?: boolean;
  flex?: boolean;
  color?: string;
  borderRadius?: number;
  spacing?:
    | {
        top?: number;
        bottom?: number;
        left?: number;
        right?: number;
      }
    | number
    | null;
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];

  style?: React.CSSProperties;
}) => {
  const basicStyle: React.CSSProperties = {
    ...(color
      ? {
          backgroundColor: color,
        }
      : {}),
    ...(flex
      ? {
          display: "flex",
        }
      : {}),
    ...(center
      ? {
          display: "flex",
          justifyContent: "center",
        }
      : {}),

    ...(borderRadius
      ? {
          borderRadius,
        }
      : {}),

    ...(spacing && typeof spacing === "number"
      ? {
          padding: spacing,
        }
      : spacing && typeof spacing === "object"
      ? {
          paddingTop: spacing.top,
          paddingBottom: spacing.bottom,
          paddingLeft: spacing.left,
          paddingRight: spacing.right,
        }
      : {}),

    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
  };

  return <div style={{ ...basicStyle, ...style }}>{children}</div>;
};
