import React from "react";

const XIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M3.31803 3.31802L4.73224 4.73223L6.50001 6.5M9.68199 9.68198L6.50001 6.5M6.50001 6.5L9.68199 3.31802L3.31803 9.68198"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default XIcon;
