import React from "react";
import firebase from "firebase/compat/app";
import service from "../Mono/service";

export const useAuth = () => {
  const [user, setUser] = React.useState<any | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [token, setToken] = React.useState<string | null>(null);

  React.useEffect(() => {
    setInterval(() => {
      firebase.app().auth().currentUser?.getIdToken().then(service.updateToken);
    }, 60000);
  }, []);

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      setLoading(true);
      if (user?.uid) {
        setUser(user);
        await firebase
          .app()
          .auth()
          .currentUser?.getIdToken(true)
          ?.then((i) => {
            setTimeout(() => {
              service.updateToken(String(i));
              setToken(String(i));
              setLoading(false);
            }, 2500);
          });
        return;
      }

      setUser(user);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const logOut = async () =>
    firebase
      .app()
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });

  const forgotPassword = async (email: string) =>
    firebase.auth().sendPasswordResetEmail(email);

  return {
    user,
    token,
    logOut,
    forgotPassword,
    loading,
  };
};
