import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Box, Spacer } from "../../Components/UI";

export const Contact = () => {
  const mode = useGetWindowMode();
  return (
    <>
      <Spacer y={mode === "web" ? 120 : 20} />
      <Box center>
        <Box
          style={{
            maxWidth: 630,

            overflow: "hidden",
            margin: 0,
            height: "auto",
            width: "100%",
            padding: 20,
          }}
        >
          <div style={{ color: "white" }}>
            <h1>ゆらぐら コンタクト</h1>
            <h2>サポート：質問：リクエスト</h2>
            <ul>
              <li>
                <strong>Eメール</strong>:{" "}
                <a href="mailto：home@giv.ink" target="_blank" rel="noreferrer">
                  home@giv.ink
                </a>
              </li>
            </ul>
            <h2>営業</h2>
            <ul>
              <li>
                <strong>Eメール</strong>:{" "}
                <a
                  href="mailto：admin@nycroft.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  admin@nycroft.com
                </a>
              </li>
            </ul>
          </div>
          <Box height={120} />
        </Box>
      </Box>
    </>
  );
};
