import React from 'react';

export function useCreateQueryHook<Type>(
  query: (params: any) => Promise<Type>,
  params?: any,
): {
  val: Type | null;
  setVal: (t: Type) => void;
  reload: () => Promise<void>;
  loading: boolean;
} {
  const [val, setVal] = React.useState<Type | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (params) {
      query(params).then(r => {
        setVal(r);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const reload = async () => {
    if (!params) return;

    setLoading(true);
    return await query(params).then(r => {
      setVal(r);
      setLoading(false);
    });
  };

  return {
    val,
    setVal,
    reload,
    loading,
  };
}
