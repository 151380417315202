import React from "react";
import { Text } from "./Text";
import { Colors } from "../../../Mono/consts/Colors";
import { Touchable } from "./Touchable";
import { Box } from "./Box";

export enum ButtonVariants {
  Blue = "blue",
  White = "white",
  Pink = "pink",

  Dark = "dark",
  Red = "red",
}

export enum ButtonSizes {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

const toSize = (size: ButtonSizes) => {
  switch (size) {
    case ButtonSizes.Small:
      return 14;
    case ButtonSizes.Medium:
      return 16;
    case ButtonSizes.Large:
      return 18;
  }
};

const toVariant = (variant: ButtonVariants) => {
  switch (variant) {
    case ButtonVariants.Blue:
      return { button: Colors.LightBlue, text: Colors.White };
    case ButtonVariants.Pink:
      return { button: Colors.Pink, text: Colors.White };
    case ButtonVariants.White:
      return { button: Colors.White, text: Colors.Black1 };
    case ButtonVariants.Dark:
      return { button: Colors.Black2, text: Colors.White };
    case ButtonVariants.Red:
      return { button: Colors.Red, text: Colors.White };
  }
};

export const Button = ({
  text,
  onPress,
  size = ButtonSizes.Medium,
  variant = ButtonVariants.Pink,
  bold,
  center,
  styles,
}: {
  text: string | number;
  onPress: () => void;
  size?: ButtonSizes;
  variant?: ButtonVariants;
  bold?: boolean;
  center?: boolean;
  styles?: React.CSSProperties;
}) => {
  const buttonBasicStyle: React.CSSProperties = {
    justifyContent: "center",

    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 12,
    paddingRight: 12,

    borderRadius: 10,
    backgroundColor: toVariant(variant).button,
    cursor: "pointer",
  };

  const textBasicStyle: React.CSSProperties = {
    color: toVariant(variant).text,
    fontSize: toSize(size),
    fontWeight: bold ? "bold" : "normal",
    textAlign: center ? "center" : "left",
  };

  return (
    <Touchable onPress={onPress}>
      <Box style={{ ...buttonBasicStyle, ...styles }}>
        <Text styles={textBasicStyle}>{text}</Text>
      </Box>
    </Touchable>
  );
};
