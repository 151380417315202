import React from "react";

import { Colors } from "../../../Mono/consts/Colors";
import { YgraPurpleGlowLogo } from "../../assets/YgraPurpleGlowLogo";

const screenStyle: React.CSSProperties = {
  width: "100%",
  height: "100vh",
  backgroundColor: Colors.Black3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const logoStyle: React.CSSProperties = {
  width: 90,
  height: 90,
};

export const BootScreen = () => (
  <div style={screenStyle}>
    <YgraPurpleGlowLogo style={logoStyle} />
  </div>
);
