import React from "react";
import { CreatePostContent } from "./CreatePostContent";
import { AddTagsForm } from "./AddTags";
import {
  PostContentType,
  PostFormContent,
} from "../../../Mono/tools/postExtract";
import service from "../../../Mono/service";
import { useGetPost, useGetTagsFromSearch } from "../../../Mono/queryHooks";
import { useNavigate, useParams } from "react-router-dom";
import { BottomSheet } from "../../BottomSheet/BottomSheet";
import { LoadingOverlay } from "../../Components/UX/LoadingOverlay";
import { Box, Spacer } from "../../Components/UI";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";

const createPost = async ({
  postFormContent,
  nsfw,
  replyToId,
  tags,
}: {
  postFormContent: PostFormContent[];
  nsfw: boolean;
  replyToId?: string;
  tags: string[];
}) => {
  const formData = new FormData();

  postFormContent
    .filter((p) => p.type === "image")
    .forEach((content) => {
      // @ts-ignore
      formData.append("images", content.image, content.id);
    });

  const stringifiedPostFormContent = postFormContent
    .map((content) => {
      if (content.type === "image") return `[image:(${content.id})]`;
      else if (content.type === "string") return content.content;
    })
    .join(" ");

  formData.append("content", stringifiedPostFormContent);
  formData.append("nsfw", String(nsfw));
  formData.append("tags", JSON.stringify(tags));

  if (replyToId) {
    formData.append("replyToId", replyToId);
  }

  return await service.api().posts.createPost(formData);
};

export const CreatePost = () => {
  const { replyToId } = useParams();

  const navigate = useNavigate();
  const mode = useGetWindowMode();

  const { val: replyToPost } = useGetPost(replyToId || "");

  const [postFormContent, setPostFormContent] = React.useState<
    PostFormContent[]
  >([{ type: PostContentType.String, content: "" }]);

  const [tagField, setTagField] = React.useState<string>("");
  const [tagsFormOpen, setTagsFormOpen] = React.useState<boolean>(false);
  const [tags, setTags] = React.useState<string[]>([]);
  const [nsfw, setNsfw] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const navGoBack = () => navigate(-1);

  const onSubmit = async () => {
    setLoading(true);
    createPost({
      postFormContent,
      nsfw,
      replyToId: replyToId || undefined,
      tags,
    }).then(() => {
      navGoBack();
      setLoading(false);
    });
  };

  const searchedTagsResult = useGetTagsFromSearch(tagField);
  const searchedTags = searchedTagsResult?.val?.map((i) => i.tag) || [];

  const isMaxTags = tags.length >= 6;
  const contentIsSubmittable =
    // @ts-ignore
    postFormContent.find((i) => i.type === "string")?.content.length ||
    postFormContent.find((i) => i.type === "image");

  const openTagsSheet = () => {
    setTagsFormOpen(true);
  };

  const closeTagsSheet = () => {
    setTagsFormOpen(false);
  };

  const addImage = async (file: File) => {
    if (!file) {
      return;
    }

    const id =
      (postFormContent.filter((a) => a.type === "image").length || 0) + 1;

    setPostFormContent([
      ...postFormContent,
      {
        type: PostContentType.Image,
        image: file,
        id: id,
      },
    ]);
  };

  const tagOptions: string[] = [
    ...(searchedTags.filter((e) => !tags.includes(e)) || []),
    ...(tagField &&
    !tags.includes(tagField) &&
    !searchedTags?.map((i) => i).includes(tagField)
      ? [tagField]
      : []),
  ];

  return (
    <>
      <Spacer y={mode === "web" ? 110 : 10} />
      <Box center>
        <Box
          spacing={
            mode === "web"
              ? { left: 15, right: 15 }
              : {
                  left: 10,
                  right: 10,
                }
          }
          style={{
            width: "100%",
            maxWidth: 630,
            zIndex: 1,
          }}
        >
          <CreatePostContent
            onSubmit={onSubmit}
            postFormContent={postFormContent}
            setPostFormContent={setPostFormContent}
            addImage={addImage}
            addTag={openTagsSheet}
            tags={tags}
            replyToPost={replyToPost}
            goBack={navGoBack}
            contentIsSubmittable={contentIsSubmittable}
            nsfw={nsfw}
            setNsfw={setNsfw}
          />

          {tagsFormOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 100,
              }}
            >
              <BottomSheet
                children={
                  <>
                    <AddTagsForm
                      tags={tags}
                      setTags={setTags}
                      tagField={tagField}
                      setTagField={setTagField}
                      tagOptions={tagOptions}
                      isMaxTags={isMaxTags}
                    />
                    <Spacer y={65} />
                  </>
                }
                display={tagsFormOpen}
                closeModal={closeTagsSheet}
              />
            </div>
          )}
        </Box>
      </Box>
      <LoadingOverlay display={loading} />
    </>
  );
};
