import React from "react";

const LeftIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M8.25 3.25L4.75 6.75L8.25 10.25"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LeftIcon;
