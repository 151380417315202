import React from "react";

import { Box, Text } from "../UI";
import { Colors } from "../../../Mono/consts/Colors";

export const LoadingOverlay = ({ display }: { display: boolean }) =>
  display ? (
    <Box
      center
      width="100%"
      height="100%"
      style={{
        position: "fixed",
        top: 0,
        zIndex: 100,
        alignItems: "center",
      }}
    >
      <Box
        width="100%"
        height="100%"
        color={Colors.Black3}
        style={{
          position: "absolute",
          opacity: 0.5,
        }}
      />
      <Text styles={{ color: Colors.White, zIndex: 1 }}>読み込み中</Text>
    </Box>
  ) : null;
