import React from "react";
import { Colors } from "../../../Mono/consts/Colors";
import { Box } from "../UI/Box";
import { HStack } from "../UI/HStack";

export const MasonryLoadingComponent = () => (
  <HStack>
    <Box width="50%">
      <Box spacing={5}>
        <Box
          flex
          height={100}
          color={Colors.Black1}
          spacing={5}
          borderRadius={15}
        />
      </Box>
      <Box height={10} />
      <Box spacing={5}>
        <Box
          flex
          height={260}
          color={Colors.Black1}
          spacing={5}
          borderRadius={15}
        />
      </Box>
    </Box>

    <Box width="50%">
      <Box spacing={5}>
        <Box flex height={260} color={Colors.Black1} borderRadius={15} />
      </Box>
      <Box height={10} />
      <Box spacing={5}>
        <Box
          flex
          height={70}
          color={Colors.Black1}
          spacing={5}
          borderRadius={15}
        />
      </Box>
    </Box>
  </HStack>
);
