import React from "react";

import { AppRoutes } from "./Routes";
import { BrowserRouter } from "react-router-dom";

import firebase from "firebase/compat/app";
import { initialiseAuth } from "./initialiseService";
import { ModalProvider } from "./Content/BottomSheet/BottomSheetContext";

const App = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyAVjp1LxgxZnu3EE2P3osKRk6gEe9YWKNM",
    authDomain: "ygra4-96d4d.firebaseapp.com",
    projectId: "ygra4-96d4d",
    storageBucket: "ygra4-96d4d.appspot.com",
    messagingSenderId: "393768102955",
    appId: "1:393768102955:web:e39cd560c7c04572aace6e",
    measurementId: "G-CFG2GY1T85",
  });

  React.useEffect(() => {
    initialiseAuth();
  }, []);

  return (
    <BrowserRouter>
      <ModalProvider>
        <AppRoutes />
      </ModalProvider>
    </BrowserRouter>
  );
};

export default App;
