import React from "react";

const ShareIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg style={style} viewBox="0 0 13 13" fill="none">
    <path
      d="M10.8995 1.53553L0.999992 4.36396L5.24263 7.19239M10.8995 1.53553L8.07106 11.435L5.24263 7.19239M10.8995 1.53553L5.24263 7.19239"
      stroke="white"
      stroke-linejoin="round"
    />
  </svg>
);

export default ShareIcon;
