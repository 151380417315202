import { MasonryComponent } from "../../Components/UX/Masonry";
import React from "react";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { RouteName } from "../../../Routes";
import { HeadBanner, MasonryLoadingComponent } from "../../Components/UX";
import { bannerUri } from "../../../Mono/consts/bannerUri";
import {
  Box,
  HStack,
  Spacer,
  Text,
  TextVariants,
  Touchable,
} from "../../Components/UI";
import { Icon, IconType } from "../../assets/icons";
import {
  useGetPopularPosts,
  useGetPopularTags,
  useGetTagsSaved,
} from "../../../Mono/queryHooks";
import { PageContainer } from "../../Components/UX/Layout/PageContainer";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Post } from "../../../Mono/types";
import { Colors } from "../../../Mono/consts/Colors";
import { stringToColor } from "../../../Mono/tools/stringToColor";

export const Explore = () => {
  const mode = useGetWindowMode();
  const { posts, getMore } = useGetPopularPosts();

  React.useEffect(() => {
    if (posts && posts?.length < 20) {
      getMore();
    }
  }, [posts]);

  const { val: savedTags } = useGetTagsSaved();
  const tagIsSaved = savedTags?.map((t) => t.tag);

  const linkTo = useLinkTo();
  const onClickPost = (post: Post) => {
    linkTo(RouteName.Post, { postId: post.id });
  };

  const navigateToSearch = () => linkTo(RouteName.Search);
  const navigateToTag = (tag: string) => linkTo(RouteName.Tag, { tag });

  const { val: popularTags } = useGetPopularTags();
  const tags = popularTags?.slice(0, 8) || [];

  return (
    <PageContainer onEndReached={getMore}>
      <Box center>
        <Box
          spacing={
            mode === "web"
              ? { left: 15, right: 15 }
              : {
                  left: 10,
                  right: 10,
                }
          }
          style={{
            width: "100%",
            maxWidth: 1000,
            zIndex: 1,
          }}
        >
          <Spacer y={mode === "web" ? 110 : 10} />
          <Touchable onPress={navigateToSearch}>
            <Box
              spacing={{
                left: 10,
                right: 10,
              }}
              borderRadius={20}
              color={Colors.Black2}
              center
            >
              <Box spacing={15}>
                <HStack center>
                  <Icon size={18} type={IconType.Search} />
                  <Spacer x={10} />
                  <Text color={Colors.Grey} bold>
                    検索
                  </Text>
                </HStack>
              </Box>
            </Box>
          </Touchable>
          <Spacer y={10} />
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 10,
            }}
          >
            {tags &&
              tags.map((item) => (
                <Touchable onPress={() => navigateToTag(item.tag)}>
                  <Box
                    color={stringToColor(item.tag) + "60"}
                    borderRadius={15}
                    spacing={5}
                    height={60}
                    center
                    style={{ alignItems: "center" }}
                  >
                    <HStack center>
                      <Icon type={IconType.Hashtag} size={17} />
                      <Spacer x={5} />
                      <Text bold size={TextVariants.Small}>
                        {item.tag}
                      </Text>
                    </HStack>
                  </Box>
                </Touchable>
              ))}

            <Spacer y={10} />
          </Box>
        </Box>
      </Box>
      <Spacer y={10} />
      <Box center>
        <Box
          spacing={
            mode === "web"
              ? { left: 15, right: 15 }
              : {
                  left: 10,
                  right: 10,
                }
          }
          style={{
            width: "100%",
            maxWidth: 1000,
            zIndex: 1,
          }}
        >
          {posts ? (
            <MasonryComponent posts={posts} onClick={onClickPost} />
          ) : (
            <MasonryLoadingComponent />
          )}
        </Box>
      </Box>
    </PageContainer>
  );
};
