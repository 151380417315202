import React from "react";
import { Colors } from "../../../Mono/consts/Colors";

export type TextInputType = "text" | "password";

const basicStyle: React.CSSProperties = {
  backgroundColor: Colors.Black1,
  color: Colors.White,
  padding: 15,

  borderRadius: 15,
  border: "none",
};

export const TextInput = ({
  value,
  placeholder,
  type = "text",
  style,
  onChange,
}: {
  value: string;
  placeholder: string;
  type?: TextInputType;
  style?: React.CSSProperties;
  onChange: (text: string) => void;
}) => (
  <input
    placeholder={placeholder}
    // placeholderTextColor={Colors.Grey}
    style={{ ...basicStyle, ...style }}
    type={type}
    value={value}
    onChange={(e) => onChange(e.target.value)}
  />
);
