import React from "react";

const AddIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M6.5 2V4V6.5M6.5 11V6.5M6.5 6.5H11H2"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default AddIcon;
