import React from "react";
import { MasonryComponent } from "../../Components/UX/Masonry";

import "react-indiana-drag-scroll/dist/style.css";
import { MasonryLoadingComponent, TextInput } from "../../Components/UX";
import { RouteName } from "../../../Routes";
import { Box, HStack, Spacer, Touchable, Text } from "../../Components/UI";
import {
  useGetPopularPosts,
  useGetPopularTags,
  useGetPostsFromSearch,
  useGetTagsFromSearch,
} from "../../../Mono/queryHooks";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { Icon, IconType } from "../../assets/icons";
import { Colors } from "../../../Mono/consts/Colors";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { PageContainer } from "../../Components/UX/Layout/PageContainer";
import { Post } from "../../../Mono/types";

export const Search = () => {
  const mode = useGetWindowMode();
  const linkTo = useLinkTo();

  const {
    posts: popularPosts,
    getMore: getMorePopularPosts,
    loading: loadingPopularPosts,
  } = useGetPopularPosts();

  const { val: popularTags } = useGetPopularTags();

  const [search, setSearch] = React.useState("");

  const {
    posts: searchedPosts,
    getMore: getMoreSearchPosts,
    loading: loadingSearchPosts,
  } = useGetPostsFromSearch(search);

  const { val: searchedTags } = useGetTagsFromSearch(search);

  const tags = search ? searchedTags : popularTags?.slice(0, 6) || [];
  const posts = search ? searchedPosts : popularPosts;
  const loading = search
    ? loadingSearchPosts && !searchedPosts
    : loadingPopularPosts && !popularPosts;

  const getMore = search ? getMoreSearchPosts : getMorePopularPosts;

  const onClickPost = (post: Post) => {
    linkTo(RouteName.Post, {
      postId: post.id,
    });
  };

  const onPressHashtag = (tag: string) => {
    linkTo(RouteName.Tag, {
      tag,
    });
  };

  return (
    <PageContainer onEndReached={getMore}>
      <Spacer y={mode === "web" ? 110 : 10} />

      <Box center>
        <Box style={{ position: "relative", maxWidth: 800, width: "100%" }}>
          <>
            <Spacer y={10} />
            <HStack center>
              <Spacer x={10} />
              <Touchable onPress={() => linkTo(RouteName.Explore)}>
                <Icon type={IconType.Left} size={30} />
              </Touchable>
              <Spacer x={10} />
              <TextInput
                value={search}
                placeholder="検索"
                onChange={setSearch}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 18,
                  padding: 10,
                  flex: 1,
                  margin: 0,
                }}
              />
              <Spacer x={20} />
            </HStack>
            <Spacer y={10} />
          </>
          {tags?.length ? (
            <>
              <Box color={Colors.Black1} borderRadius={15}>
                <Box
                  spacing={{
                    left: 20,
                    right: 20,
                    top: 15,
                    bottom: 20,
                  }}
                >
                  {tags?.map((hashtag, index) => (
                    <Touchable
                      key={index}
                      onPress={() => onPressHashtag(hashtag.tag)}
                    >
                      <Text text={`# ${hashtag.tag}`} />
                      <Spacer y={6} />
                      <Text
                        text={`${hashtag.count} ポスト`}
                        size={16}
                        color={Colors.Grey}
                      />

                      {index !== tags.length - 1 && <Spacer y={15} />}
                    </Touchable>
                  ))}
                </Box>
              </Box>
              <Spacer y={15} />
            </>
          ) : null}
        </Box>
      </Box>

      <Spacer y={20} />

      <Box center>
        <Box
          spacing={
            mode === "web"
              ? { left: 15, right: 15 }
              : {
                  left: 10,
                  right: 10,
                }
          }
          style={{
            width: "100%",
            maxWidth: 1000,
            zIndex: 1,
          }}
        >
          {loading ? (
            <MasonryLoadingComponent />
          ) : posts?.length ? (
            <MasonryComponent posts={posts} onClick={onClickPost} />
          ) : null}
        </Box>
      </Box>
    </PageContainer>
  );
};
