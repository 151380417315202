import firebase from "firebase/compat/app";
import service, { AuthProxy } from "./Mono/service";

export const initialiseAuth = () => {
  const authProxy: AuthProxy = {
    onAuthStateChanged: (callback) => {
      firebase.auth().onAuthStateChanged(callback);
    },
  };

  service.configure({ auth: authProxy });
};
