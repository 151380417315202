import {serverURL} from '../consts/serverURL';
import {System} from '../types/System';

export interface Report {
  reason: string | null;
  postId: string | null;
}

export const apiCreateReport =
  (token: System['token']) => async (report: Report) =>
    await fetch(`${serverURL}/reports`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
      body: JSON.stringify(report),
    }).then(res => res.json());
