import React from "react";
export const YgraPurpleGlowLogo = ({
  style,
}: {
  style: React.CSSProperties;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 120 114"
    style={style}
    fill="none"
  >
    <path
      d="M102.428 1.01144L98.2049 22.3279L120 22.0791C118.96 30.8517 116.55 40.4041 111.134 44.8943C99.7644 54.3199 68.8029 46.7025 68.8029 46.7025C68.8029 46.7025 66.9292 14.9699 78.2983 5.54434C83.716 1.05284 93.5825 0.431332 102.428 1.01144Z"
      fill="url(#paint0_linear_652_5)"
    />
    <path
      d="M0 19.3699L21.6849 21.6081L19.4396 0C28.2999 0.228154 38.0649 1.74561 43.0473 6.7095C53.5063 17.1295 48.7319 48.5556 48.7319 48.5556C48.7319 48.5556 17.1985 53.3223 6.73948 42.9022C1.75555 37.9369 0.230705 28.2016 0 19.3699Z"
      fill="url(#paint1_linear_652_5)"
    />
    <path
      d="M19.4392 114L21.6849 92.3876L0.00434051 94.6254C0.23326 85.7947 1.75581 76.0624 6.73634 71.0966C17.1913 60.6726 48.7228 65.431 48.7228 65.431C48.7228 65.431 53.5054 96.859 43.0505 107.283C38.0685 112.25 28.3005 113.77 19.4392 114Z"
      fill="url(#paint2_linear_652_5)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_652_5"
        x1="34.5"
        y1="18"
        x2="90"
        y2="100.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A359ED" />
        <stop offset="1" stop-color="#4A66CA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_652_5"
        x1="34.5"
        y1="18"
        x2="90"
        y2="100.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A359ED" />
        <stop offset="1" stop-color="#4A66CA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_652_5"
        x1="34.5"
        y1="18"
        x2="90"
        y2="100.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A359ED" />
        <stop offset="1" stop-color="#4A66CA" />
      </linearGradient>
    </defs>
  </svg>
);
