import {serverURL} from '../consts/serverURL';
import {Post} from '../types/Post';
import {System} from '../types/System';
import {User} from '../types/User';

export const apiGetPosts =
  (token: System['token']) => async (offset?: number) => {
    const data = await fetch(
      `${serverURL}/posts${offset ? '?offset=' + offset : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: String(token),
        },
      },
    ).then(res => res.json());

    return data as Post[];
  };

export const apiGetPostReplies =
  (token: System['token']) => async (id: Post['id'], offset?: number) => {
    const data = await fetch(
      `${serverURL}/posts/${id}/replies${offset ? '?offset=' + offset : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: String(token),
        },
      },
    ).then(res => res.json());

    return data as Post[];
  };

export const apiGetPost =
  (token: System['token']) => async (id: Post['id']) => {
    const data = await fetch(`${serverURL}/posts/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
    }).then(res => res.json());

    return data as Post;
  };

export const apiCreatePost =
  (token: System['token']) => async (formData: FormData) => {
    const data = await fetch(`${serverURL}/posts/create`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      headers: {
        Authorization: String(token),
      },
      body: formData,
    }).then(res => res.json());

    return data as Post;
  };

export const apiGetPostsFromIds =
  (token: System['token']) => async (ids: string[]) => {
    const data = await fetch(
      `${serverURL}/posts/derive-ids/${JSON.stringify(ids)}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: String(token),
        },
      },
    ).then(res => res.json());

    return data as Post[];
  };

export const apiGetUserPosts =
  (token: System['token']) => async (id: string, offset?: number) => {
    const data = await fetch(
      `${serverURL}/posts/user/${id}${offset ? '?offset=' + offset : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: String(token),
        },
      },
    ).then(res => res.json());

    return data as Post[];
  };

export const apiGetUserSaves =
  (token: System['token']) => async (id: User['id'], offset?: number) => {
    const data = await fetch(
      `${serverURL}/posts/saves/user/${id}${offset ? '?offset=' + offset : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: String(token),
        },
      },
    ).then(res => res.json());

    return data as Post[];
  };

export const apiGetPostSaves =
  (token: System['token']) => async (id: Post['id']) => {
    const data = await fetch(`${serverURL}/posts/${id}/saves`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
    }).then(res => res.json());

    return data as Post[];
  };

export const apiCreateSave =
  (token: System['token']) => async (id: Post['id']) =>
    await fetch(`${serverURL}/posts/saves`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
      body: JSON.stringify({id}),
    }).then(res => res.json());

export const apiDeleteSave =
  (token: System['token']) => async (id: Post['id']) =>
    await fetch(`${serverURL}/posts/saves`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
      body: JSON.stringify({id}),
    }).then(res => res.json());

export const apiGetPostsFromFollowingUsers =
  (token: System['token']) => async (offset?: number) => {
    const data = await fetch(
      `${serverURL}/following/posts${offset ? '?offset=' + offset : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: String(token),
        },
      },
    ).then(res => res.json());

    return data as Post[];
  };

export const apiGetTagPosts =
  (token: System['token']) => async (tag: string, offset?: number) => {
    const data = await fetch(
      `${serverURL}/posts/tag/${tag}${offset ? '?offset=' + offset : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: String(token),
        },
      },
    ).then(res => res.json());

    return data as Post[];
  };

export const apiGetTagsPosts =
  (token: System['token']) => async (tags: string, offset?: number) => {
    const data = await fetch(
      `${serverURL}/posts/tags/${tags}${offset ? '?offset=' + offset : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: String(token),
        },
      },
    ).then(res => res.json());

    return data as Post[];
  };

export const apiUndoRePost =
  (token: System['token']) => async (id: Post['id']) =>
    await fetch(`${serverURL}/posts/repost/${id}`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
    }).then(res => res.json());

export const apiDeletePost =
  (token: System['token']) => async (id: Post['id']) =>
    await fetch(`${serverURL}/posts/${id}`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
    }).then(res => res.json());

export const apiGetPostsFromSearch =
  (token: System['token']) => async (search: string | undefined) => {
    const data = await fetch(`${serverURL}/posts/search/${search}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(token),
      },
    }).then(res => res.json());

    return data as Post[];
  };

export const apiGetUserReplies =
  (token: System['token']) => async (offset?: number) => {
    const data = await fetch(
      `${serverURL}/posts/user/me/replies${offset ? '?offset=' + offset : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: String(token),
        },
      },
    ).then(res => res.json());

    return data as Post[];
  };
