import React from "react";

export const useWindowWidth = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setWidth(window.innerWidth);
    }

    // Subscribe to window resize event
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures effect runs only on mount and unmount

  return width;
};

export const useGetWindowMode = () => {
  const [mode, setMode] = React.useState<"web" | "mobile">("web");

  const width = useWindowWidth();
  React.useEffect(() => {
    if (width < 600) {
      setMode("mobile");
    } else {
      setMode("web");
    }
  }, [width]);

  return mode;
};
