import React from "react";
import service from "../../../Mono/service";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import {
  Box,
  Button,
  ButtonVariants,
  HStack,
  Spacer,
  Text,
} from "../../Components/UI";
import { TextInput } from "../../Components/UX";

export const DeleteUser = () => {
  const mode = useGetWindowMode();
  const [hangName, setHangName] = React.useState("");
  const onChange = (text: string) => {
    setHangName(text.toLowerCase());
  };

  const onSubmit = () => {
    if (hangName === service.state.user?.hangName) {
      service
        .api()
        .users.deleteUser()
        .then(() => {
          window.location.reload();
        });
    }
  };

  const canSubmit = hangName === service.state.user?.hangName;

  return (
    <>
      <Spacer y={mode === "web" ? 120 : 20} />
      <Box center>
        <Box
          style={{
            maxWidth: 630,

            overflow: "hidden",
            margin: 0,
            height: "auto",
            width: "100%",
          }}
        >
          <Spacer y={20} />

          <Text
            text={`「@${service.state.user?.hangName}」 アカウントを削除`}
          />
          <Box height={20} />
          <HStack center>
            <Box spacing={{ right: 10 }}>
              <Text text="@" />
            </Box>
            <TextInput
              placeholder="削除するには一度ハングネームを入力して下さい"
              value={hangName}
              onChange={onChange}
              style={{ display: "flex", flex: 1 }}
            />
          </HStack>

          <Spacer y={20} />

          <Button
            onPress={() => (canSubmit ? onSubmit() : null)}
            variant={canSubmit ? ButtonVariants.Pink : ButtonVariants.Dark}
            center
            bold
            text="保存"
          />
        </Box>
      </Box>
    </>
  );
};
