import React from "react";

import { Icon, IconType } from "../../../assets/icons";
import { Colors } from "../../../../Mono/consts/Colors";
import { Box, Text, Touchable } from "../../UI";

const styles: { [key: string]: React.CSSProperties } = {
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    padding: 8,
    paddingTop: 20,
    paddingBottom: 5,
  },
  icon: {
    width: 40,
    height: 40,
    alignItems: "center",
  },
  text: {
    color: Colors.White,
    fontSize: 18,
  },
};

export const HeaderComponent = ({
  leftButton,
  rightButton,
  title,
}: {
  leftButton?: {
    icon: IconType;
    onPress: () => void;
  };
  rightButton?: {
    icon: IconType;
    onPress: () => void;
  };
  title?: string;
}) => (
  <Box style={styles.header}>
    {leftButton ? (
      <Touchable onPress={leftButton.onPress}>
        <Box style={styles.icon}>
          <Icon size={25} type={leftButton.icon} />
        </Box>
      </Touchable>
    ) : (
      <Box width={40} height={40} />
    )}

    {title ? <Text styles={styles.text}>{title}</Text> : null}
    {rightButton ? (
      <Touchable onPress={rightButton.onPress}>
        <Box style={styles.icon}>
          <Icon size={25} type={rightButton.icon} />
        </Box>
      </Touchable>
    ) : (
      <Box width={40} height={40} />
    )}
  </Box>
);
