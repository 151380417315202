import React from "react";

const ProfileIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <circle cx="6.5" cy="3" r="1" fill="white" />
    <circle cx="6.5" cy="6" r="1" fill="white" />
    <circle cx="6.5" cy="9" r="1" fill="white" />
  </svg>
);

export default ProfileIcon;
