import { useRef } from "react";

export const FileUploadComponent = ({
  onFileUpload,
  component,
}: {
  onFileUpload: (file: File) => void;
  component: (onButtonClick: () => void) => React.ReactNode;
}) => {
  const inputFileRef = useRef(null);
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    onFileUpload(e.target.files[0]);
  };
  const onBtnClick = () => {
    // @ts-ignore
    inputFileRef?.current?.click();
  };

  return (
    <div>
      <input
        type="file"
        ref={inputFileRef}
        onChange={(e) => onFileChange(e)}
        style={{ display: "none" }}
      />
      {component(onBtnClick)}
    </div>
  );
};
