import React from "react";

import AddIcon from "./AddIcon";
import BellIcon from "./BellIcon";
import BottomIcon from "./BottomIcon";
import BottomIconGrey from "./BottomIconGrey";
import CheckIcon from "./CheckIcon";
import HashtagIcon from "./HashtagIcon";
import HashtagIconGrey from "./HashtagIconGrey";
import HomeIcon from "./HomeIcon";
import ImageIcon from "./ImageIcon";
import LeftIcon from "./LeftIcon";
import ProfileIcon from "./ProfileIcon";
import ReplyIcon from "./ReplyIcon";
import ReplyIconGrey from "./ReplyIconGrey";
import RePostIcon from "./RePostIcon";
import RePostIconGreen from "./RePostIconGreen";
import RePostIconGrey from "./RePostIconGrey";
import SaveIcon from "./SaveIcon";
import SaveIconGrey from "./SaveIconGrey";
import SaveIconRed from "./SaveIconRed";
import ShieldIcon from "./ShieldIcon";
import SaveIconWhite from "./SaveIconWhite";
import SettingsIcon from "./SettingsIcon";
import SearchIcon from "./SerarchIcon";
import ShareIcon from "./ShareIcon";
import ShareIconGrey from "./ShareIconGrey";
import TripleDotIcon from "./TripleDotIcon";
import TripleLineIcon from "./TripleLineIcon";
import XIcon from "./XIcon";

export enum IconType {
  Home = "home",
  Bell = "bell",
  Hashtag = "hashtag",
  HashtagGrey = "hashtag-grey",
  Profile = "profile",
  Save = "save",
  Repost = "repost",
  Add = "add",
  Image = "image",
  AddImage = "add-image",
  AddText = "add-text",
  Bottom = "bottom",
  SaveRed = "save-red",
  Shield = "shield",
  RepostGreen = "repost-green",

  TripleDot = "triple-dot",
  TripleLine = "triple-line",

  SaveGrey = "save-grey",
  SaveWhite = "save-white",
  RepostGrey = "repost-grey",
  BottomGrey = "bottom-grey",

  Left = "left",

  Reply = "reply",
  ReplyGrey = "reply-grey",

  Settings = "settings",
  Search = "search",

  Share = "share",
  ShareGrey = "share-grey",

  X = "x",

  Check = "check",
}

const getIconByType = (type: IconType, { size }: { size?: number }) => {
  switch (type) {
    case IconType.Home:
      return (
        <HomeIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Bell:
      return (
        <BellIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Hashtag:
      return (
        <HashtagIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.HashtagGrey:
      return (
        <HashtagIconGrey
          style={{
            width: size,
            height: size,
          }}
        />
      );

    case IconType.Profile:
      return (
        <ProfileIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Save:
      return (
        <SaveIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );

    case IconType.SaveGrey:
      return (
        <SaveIconGrey
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.SaveWhite:
      return (
        <SaveIconWhite
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.SaveRed:
      return (
        <SaveIconRed
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Shield:
      return (
        <ShieldIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Repost:
      return (
        <RePostIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.RepostGreen:
      return (
        <RePostIconGreen
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.RepostGrey:
      return (
        <RePostIconGrey
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Bottom:
      return (
        <BottomIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.BottomGrey:
      return (
        <BottomIconGrey
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Add:
      return (
        <AddIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Image:
      return (
        <ImageIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.X:
      return (
        <XIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.TripleDot:
      return (
        <TripleDotIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.TripleLine:
      return (
        <TripleLineIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Left:
      return (
        <LeftIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Check:
      return (
        <CheckIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Settings:
      return (
        <SettingsIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Search:
      return (
        <SearchIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Reply:
      return (
        <ReplyIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.ReplyGrey:
      return (
        <ReplyIconGrey
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.Share:
      return (
        <ShareIcon
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case IconType.ShareGrey:
      return (
        <ShareIconGrey
          style={{
            width: size,
            height: size,
          }}
        />
      );
    default:
      return null;
  }
};

export const Icon = ({ type, size }: { type: IconType; size?: number }) =>
  getIconByType(type, { size });
