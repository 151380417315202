import { contactUri } from "../../Mono/consts/contactUri";
import { termsAndConditionsUri } from "../../Mono/consts/termsAndCondtitinsUri";
import service from "../../Mono/service";
import { Post } from "../../Mono/types";
import { RouteName } from "../../Routes";
import { IconType } from "../assets/icons";
import { ButtonsList } from "./BottomSheet";

export interface PostModalProps {
  postId: Post["id"];
  userId: Post["id"] | null;
  onDeletePost: () => void;
}

export const postModal = ({
  params,
  onClose,
  onDeletePost,
}: {
  params: PostModalProps;
  onClose: () => void;
  onDeletePost: () => void;
}) => {
  const postOptions = [
    ...(service.state?.user?.id === params?.userId ||
    service.state.user?.role === "moderator"
      ? [
          {
            label: "ポストを削除する",
            onPress: () => {
              service
                .api()
                .posts.deletePost(params.postId)
                .then(() => {
                  onDeletePost();
                });
            },
          },
        ]
      : []),
    {
      label: "スパム報告",
      onPress: () =>
        service
          .api()
          .reports.createReport({
            postId: params.postId,
            reason: "spam",
          })
          .then(onClose),
    },
    {
      label: "虐待報告",
      onPress: () =>
        service
          .api()
          .reports.createReport({
            postId: params.postId,
            reason: "abuse",
          })
          .then(onClose),
    },
    {
      label: "nsfw報告",
      onPress: () =>
        service
          .api()
          .reports.createReport({
            postId: params.postId,
            reason: "nsfw",
          })
          .then(onClose),
    },
  ];

  return ButtonsList({ postOptions });
};

//
//

export interface UserModalProps {
  userId: Post["id"];
  isBlocked: boolean;
  onBlockUser: () => void;
}

export const userModal = ({
  params,
  onClose,
}: {
  params: UserModalProps;
  onClose: () => void;
}) => {
  const postOptions = [
    {
      label: params.isBlocked ? "ブロックを解除" : "ブロック",
      onPress: () =>
        (params.isBlocked
          ? service.api().blocks.deleteBlock(params.userId)
          : service.api().blocks.createBlock(params.userId)
        ).then(() => {
          onClose();
          params.onBlockUser();
        }),
    },
    {
      label: "スパム報告",
      onPress: () =>
        service
          .api()
          .reports.createReport({
            postId: null,
            reason: "spam",
          })
          .then(onClose),
    },
    {
      label: "虐待報告",
      onPress: () =>
        service
          .api()
          .reports.createReport({
            postId: null,
            reason: "abuse",
          })
          .then(onClose),
    },
    {
      label: "nsfw報告",
      onPress: () =>
        service
          .api()
          .reports.createReport({
            postId: null,
            reason: "nsfw",
          })
          .then(onClose),
    },
  ];

  return ButtonsList({ postOptions });
};

//
//

export interface SettingsModalProps {
  navigate: (route: any) => void;
}

export const settingsModal = ({
  onClose,
  onLogOut,
  params,
}: {
  onClose: () => void;
  onLogOut: () => void;
  params: SettingsModalProps;
}) => {
  const postOptions = [
    {
      label: "保存されたポスト",
      icon: IconType.Save,
      onPress: () => {
        params.navigate(RouteName.SavedPosts);
        onClose();
      },
    },
    {
      label: "自分のリプライ",
      icon: IconType.Reply,
      onPress: () => {
        params.navigate(RouteName.UserReplies);
        onClose();
      },
    },
    {
      label: "アカウント設定",
      icon: IconType.Profile,
      onPress: () => {
        params.navigate(RouteName.AccountSettings);
        onClose();
      },
    },
    {
      label: "ブロックしたユーザー",
      icon: IconType.X,
      onPress: () => {
        params.navigate(RouteName.BlockedUsers);
        onClose();
      },
    },
    {
      label: "プライバシーポリシー",
      icon: IconType.TripleDot,
      onPress: () => {
        window.open(termsAndConditionsUri);
        onClose();
      },
    },
    {
      label: "お問い合わせ",
      icon: IconType.TripleDot,
      onPress: () => {
        window.open(contactUri);
        onClose();
      },
    },
    {
      label: "ログアウト",
      icon: IconType.Left,
      onPress: () => {
        onLogOut();
        onClose();
      },
    },
  ];

  return ButtonsList({ postOptions });
};
