import React from "react";

const CheckIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg style={style} viewBox="0 0 13 13" fill="none">
    <path
      d="M3 7L5.5 10L10.5 3"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CheckIcon;
