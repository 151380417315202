import { Colors } from "../../../Mono/consts/Colors";
import { useGetBlocks } from "../../../Mono/queryHooks";
import service from "../../../Mono/service";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Box, Button, HStack, Spacer, Text } from "../../Components/UI";

export const BlockedUsers = () => {
  const mode = useGetWindowMode();

  const { val: blockedUsers, reload } = useGetBlocks();

  const undoBlock = (id: string) => {
    service.api().blocks.deleteBlock(id).then(reload);
  };

  const options = blockedUsers?.map((user) => ({
    avatar: user.avatar,
    title: user.hangName,
    onClick: () => undoBlock(user.id),
  }));

  return (
    <>
      <Spacer y={mode === "web" ? 120 : 20} />
      <Box center>
        <Box
          style={{
            maxWidth: 630,

            overflow: "hidden",
            margin: 0,
            height: "auto",
            width: "100%",
          }}
        >
          <>
            {options?.length ? (
              options.map((option) => (
                <Box>
                  <HStack spaceBetween center>
                    <HStack spaceBetween center>
                      <img
                        width={40}
                        height={40}
                        src={option.avatar}
                        style={{ borderRadius: 10 }}
                        alt="avatar"
                      />
                      <Spacer x={10} />
                      <Box center>
                        <Text text={`@${option.title}`} />
                      </Box>
                    </HStack>
                    <HStack>
                      <Spacer x={10} />
                      <HStack>
                        <Box center flex>
                          <Button
                            text="ブロックを解除"
                            onPress={option.onClick}
                          />
                        </Box>
                        <Spacer x={10} />
                      </HStack>
                    </HStack>
                  </HStack>

                  <Spacer y={10} />
                  <Box color={Colors.Grey2} height={1} flex />
                  <Spacer y={10} />
                </Box>
              ))
            ) : (
              <Box
                center
                flex
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Text text="ブロックされたユーザーはいません" />
              </Box>
            )}
          </>
        </Box>
      </Box>
    </>
  );
};
