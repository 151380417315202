import React from "react";
import { Colors } from "../../../Mono/consts/Colors";
import { TextInput } from "./TextInput";

const basicStyle: React.CSSProperties = {
  backgroundColor: Colors.Black1,
  color: Colors.White,
  borderWidth: 0,
  fontSize: 18,

  fontFamily: "Arial",
  resize: "none",

  boxShadow: "none",
  outline: "none",
};

export const TextArea = ({
  value,
  onChange,
  placeholder,
  style,
}: {
  value: string;
  onChange: (text: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <textarea
      placeholder={placeholder}
      rows={10}
      autoFocus
      style={{ ...basicStyle, ...style }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
