import React from "react";
import { Box, HStack, Spacer, Text, TextVariants, Touchable } from "../UI";
import { Colors } from "../../../Mono/consts/Colors";
import { User } from "../../../Mono/types";
import { bannerUri } from "../../../Mono/consts/bannerUri";
import { Button, ButtonVariants } from "../UI/Button";
import { Icon, IconType } from "../../assets/icons";
import { TextField } from "./PostField";
import { PostContentType } from "../../../Mono/tools/postExtract";

export const ProfileInfo = ({
  user,
  profileButtonText,
  isBlocked,
  buttonVariant,
  onProfileButtonClick,
  onClickTripleDot,
  bannerHeight = 150,
  onPressUrl,
}: {
  user: User | null;
  profileButtonText: string;
  isBlocked?: boolean;
  buttonVariant: ButtonVariants;
  onProfileButtonClick: () => void;
  onClickTripleDot?: () => void;
  bannerHeight?: number;
  onPressUrl: (url: string) => void;
}) => (
  <>
    {user?.background && !isBlocked ? (
      <Box height={bannerHeight} borderRadius={20}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 20,
            backgroundColor: Colors.Black2,
          }}
          src={isBlocked ? "" : user?.background || bannerUri}
          alt={"head-banner"}
        />
      </Box>
    ) : (
      <Box height={bannerHeight} borderRadius={20} color={Colors.Black2} />
    )}
    <Box spacing={10}>
      <HStack spaceBetween>
        <Box
          color={Colors.Black3}
          style={{
            padding: 7,
            borderRadius: 36,
            marginTop: -60,
            position: "relative",
          }}
        >
          <img
            src={user?.avatar || ""}
            alt="avatar"
            style={{
              width: 80,
              height: 80,
              borderRadius: 30,
              opacity: isBlocked ? 0.5 : 1,
            }}
          />
          {user?.role === "moderator" && (
            <Box
              color={Colors.Pink}
              style={{
                position: "absolute",
                width: 30,
                height: 30,
                borderRadius: 30,
                border: `4px solid ${Colors.Black3}`,

                alignItems: "center",
                justifyContent: "center",
                display: "flex",

                right: 0,
                bottom: 0,
                zIndex: 10,
              }}
            >
              <Icon type={IconType.Shield} size={20} />
            </Box>
          )}
        </Box>
        <Spacer x={20} />
        <HStack
          spaceBetween
          style={{
            flex: 1,
          }}
        >
          <Button
            bold
            text={profileButtonText}
            variant={buttonVariant}
            onPress={onProfileButtonClick}
          />
          {onClickTripleDot && (
            <Touchable enableOpacity onPress={onClickTripleDot}>
              <Box height={40} center>
                <Icon type={IconType.TripleDot} size={24} />
              </Box>
            </Touchable>
          )}
        </HStack>
      </HStack>
      <Box
        spacing={{
          left: 10,
          right: 10,
          top: 10,
        }}
      >
        <Text size={TextVariants.Larger} text={user?.name || ""} />
      </Box>
      <Spacer y={5} />
      <Box spacing={{ left: 10, right: 10 }}>
        <Text
          color={Colors.Grey}
          size={TextVariants.Smaller}
          text={`@${user?.hangName}` || ""}
        />
      </Box>
      <Spacer y={15} />
      {user?.description && (
        <Box spacing={{ left: 10, right: 10, bottom: 15 }}>
          <TextField
            postContent={{
              type: PostContentType.String,
              content: user?.description,
            }}
            onPressUrl={onPressUrl}
          />
        </Box>
      )}

      <Box spacing={10}>
        <HStack center>
          <HStack>
            <Text text={user?.followingCount} size={TextVariants.Small} />
            <Spacer x={5} />
            <Text
              color={Colors.Grey}
              text="フォロー"
              size={TextVariants.Smaller}
            />
          </HStack>
          <Spacer x={20} />
          <HStack center>
            <Text text={user?.followerCount} size={TextVariants.Small} />
            <Spacer x={5} />
            <Text
              color={Colors.Grey}
              text="フォロワー"
              size={TextVariants.Smaller}
            />
          </HStack>
        </HStack>
      </Box>
    </Box>
  </>
);
