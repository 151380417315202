import {
  Box,
  Button,
  HStack,
  Spacer,
  Touchable,
  Text,
} from "../../Components/UI";
import { Colors } from "../../../Mono/consts/Colors";
import service from "../../../Mono/service";
import { formatDateToISO } from "../../../Mono/tools/formatDateToISO";
import { RouteName } from "../../../Routes";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { Icon, IconType } from "../../assets/icons";

export const AccountSettings = () => {
  const mode = useGetWindowMode();
  const linkTo = useLinkTo();

  const options = [
    {
      title: "ハングネーム",
      data: "@" + service.state.user?.hangName,
      onClick: () => linkTo(RouteName.EditHangName),
    },
    {
      title: "参加日 :",
      data: service.state.user?.date
        ? formatDateToISO(service.state.user?.date)
        : "",
    },
    {
      title: "アカウントを削除する",
      onClick: () => linkTo(RouteName.DeleteUser),
    },
  ];

  return (
    <>
      <Spacer y={mode === "web" ? 120 : 20} />
      <Box center>
        <Box
          style={{
            maxWidth: 630,

            overflow: "hidden",
            margin: 0,
            height: "auto",
            width: "100%",
          }}
        >
          {options.map((option) => (
            <Touchable
              onPress={() => (option?.onClick ? option.onClick() : null)}
            >
              <Box spacing={10}>
                <HStack spaceBetween center>
                  <HStack center>
                    <Spacer x={10} />
                    <Text text={option.title} />
                  </HStack>
                  <HStack center>
                    {option.data ? <Text text={option.data} /> : null}

                    <Spacer x={10} />
                    {option.onClick ? (
                      <Box
                        style={{
                          transform: "rotate(180deg)",
                        }}
                      >
                        <Icon type={IconType.Left} size={20} />
                      </Box>
                    ) : null}
                    <Spacer x={10} />
                  </HStack>
                </HStack>
              </Box>

              <Spacer y={10} />
              <Box color={Colors.Grey2} height={1} flex />
              <Spacer y={10} />
            </Touchable>
          ))}
          <Box>
            <Box spacing={10}>
              <HStack spaceBetween center>
                <HStack>
                  <Spacer x={10} />
                  <Text text="NSFW設定" />
                </HStack>
                <HStack>
                  <Button
                    onPress={() =>
                      service
                        .api()
                        .users.updateNsfw(!service.state.user?.nsfw)
                        .then(() => {
                          window.location.reload();
                        })
                    }
                    text={service.state.user?.nsfw ? "ON" : "OFF"}
                  />

                  <Spacer x={10} />
                </HStack>
              </HStack>
            </Box>
            <Spacer y={10} />
            <Box color={Colors.Grey2} height={1} flex />
            <Spacer y={10} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
