import React from 'react';
import service from '../service';
import {Post} from '../types/Post';
import {useCreatePostsQueryHook} from './constructors/useCreatePostsQueryHook';
import {useCreateQueryHook} from './constructors/useCreateQueryHook';

export const updateCache = (posts: Post[]) => {
  const newPosts = posts.filter(
    post => !service.state.posts?.map(p => p.id).includes(post.id),
  );
  const updatedPosts = posts.filter(post =>
    service.state.posts?.map(p => p.id).includes(post.id),
  );

  const newCachedPosts = [...(service.state.posts || []), ...newPosts].map(
    p => {
      const updatedPost = updatedPosts.find(up => up.id === p.id);
      if (updatedPost) {
        return updatedPost;
      }
      return p;
    },
  );

  service.state.update.posts(newCachedPosts);
};

export const getPostCheckCache = async (id: Post['id']) => {
  const post = service.state.posts?.find(p => p.id === id);

  if (post) {
    return post;
  }

  return service.api().posts.getPost(id);
};

export const useGetPopularPosts = () =>
  useCreatePostsQueryHook({
    query: (params, offset) => service.api().posts.getPopularPosts(offset),
    cachePosts: updateCache,
    params: true,
  });

export const useGetFollowingPosts = () =>
  useCreatePostsQueryHook({
    query: (params, offset) =>
      service.api().posts.getPostsFromFollowingUsers(offset),
    cachePosts: updateCache,
    params: true,
  });

export const useGetUserPosts = (id: Post['id']) =>
  useCreatePostsQueryHook({
    query: (params, offset) => service.api().posts.getUserPosts(params, offset),
    cachePosts: updateCache,
    params: id,
  });

export const useGetTagPosts = (tags: string) =>
  useCreatePostsQueryHook({
    query: (params, offset) => service.api().posts.getTagPosts(params, offset),
    cachePosts: updateCache,
    params: tags,
  });

export const useGetTagsPosts = (tags?: string[]) =>
  useCreatePostsQueryHook({
    query: (params, offset) => service.api().posts.getTagsPosts(params, offset),
    cachePosts: updateCache,
    params: JSON.stringify(tags),
  });

export const useGetPostReplies = (id: Post['id']) =>
  useCreatePostsQueryHook({
    query: (params, offset) =>
      service.api().posts.getPostReplies(params, offset),
    cachePosts: updateCache,
    params: id,
  });

export const useGetPostSaves = (id: Post['id']) =>
  useCreatePostsQueryHook({
    query: (params, offset) => service.api().posts.getUserSaves(params, offset),
    cachePosts: updateCache,
    params: id,
  });

export const useGetPostsFromSearch = (search: string) =>
  useCreatePostsQueryHook({
    query: (params, offset) =>
      service.api().posts.getPostsFromSearch(params, offset),
    cachePosts: updateCache,
    params: search,
  });

export const useGetPost = (id: Post['id']) =>
  useCreateQueryHook<Post>(getPostCheckCache, id);

export const useGetUserReplies = () =>
  useCreatePostsQueryHook({
    query: (params, offset) => service.api().posts.getUserReplies(offset),
    cachePosts: updateCache,
    params: true,
  });

//
//
//
//

export const useGetPaginationSuggestedPostsFromTags = ({
  tags,
  currentPagination,
}: {
  tags?: string[];
  currentPagination: number;
}) => {
  const [posts, setPosts] = React.useState<Post[] | null>(null);
  const [endReached, setEndReached] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<number>(
    currentPagination || 0,
  );
  const [loading, setLoading] = React.useState(false);

  const checkEndReached = (newPosts: Post[]) => {
    if (newPosts?.length < 10) {
      setEndReached(true);
    }
  };

  const addPosts = (newPosts: Post[]) => {
    setPosts(val => [...(val || []), ...newPosts]);
  };

  const getPosts = async () =>
    await service.api().posts.getTagsPosts(JSON.stringify(tags), pagination);

  React.useEffect(() => {
    if (!tags?.length || loading || posts?.length) return;
    setPosts(null);
    setLoading(true);
    getPosts().then(r => {
      if (!r.length) {
        setEndReached(true);
        setLoading(false);
        return;
      }
      setPosts(r);
      updateCache(r);
      setLoading(false);
      checkEndReached(r);
      setPagination(val => val + 10);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const reload = async () => {
    getPosts().then(r => {
      if (!r?.length) {
        setEndReached(true);
        setLoading(false);
        return;
      }

      addPosts(r);
      setLoading(false);
      checkEndReached(r);
      setPagination(val => val + 10);
    });
  };

  const getMore = () => {
    if (loading) return;
    setLoading(true);
    getPosts().then(r => {
      if (r?.length === 0) {
        setEndReached(true);
        setLoading(false);
        return;
      }
      addPosts(r);
      updateCache(r);
      setLoading(false);
      checkEndReached(r);
      setPagination(val => val + 10);
    });
  };

  const clear = () => {
    setPosts(null);
    setLoading(false);
  };

  return {
    posts,
    endReached,
    reload,
    getMore,
    clear,
    loading,
  };
};
